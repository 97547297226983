import React, { FC, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { Row } from 'atoms/Layout';
import AddChannelCard from '../organisms/AddChannelCard';
import ChannelCard from '../organisms/ChannelCard';
import { Channel, ChannelCategory } from 'api/channels';
import Loading from '../atoms/Loading';

const ChannelCards: FC = () => {
    const { channels, feedback } = useAppContext()
    const space = useAppContext().space
    const allChannels: Channel = {
        id: "all",
        spaceId: space.value?.id || "",
        name: "All Channels",
        category: ChannelCategory.PHYSICAL_LOCATION
    }

    useEffect(() => {
        void channels.load()
        void feedback.load()
    }, [channels])

    if (channels.loading) return <Row center size="full">
        <Loading size="large" />
    </Row>

    return <Row size="full" gap="medium">
        {channels.value?.map(channel => {
           return <ChannelCard key={channel.id} channel={channel} />
        })}
        <AddChannelCard />
    </Row>
}

export default ChannelCards;
