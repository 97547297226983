import styled from "styled-components";
import colors from "../../atoms/colors";

interface Props {
    value: string;
    onChange: (value: string) => void;
}

const StyledInput = styled.input`
    padding: 24px 8px;
    border: 1px solid ${colors.brand};
    border-radius: 4px;
`;

const LightInput = ({ value, onChange }: Props) => {
    return <StyledInput value={value} onChange={e => onChange(e.target.value)} />
}

export default LightInput;