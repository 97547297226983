import Stage1Account from "./Stage1Account";
import React, {useState} from "react";
import Stage2Welcome from "./Stage2Welcome";
import Stage3CompanyInfo from "./Stage3CompanyInfo";
import Stage4Question from "./Stage4Question";
import Stage5ExtraInfo from "./Stage5ExtraInfo";
import Stage8SettingUp from "./Stage8SettingUp";
import {useNavigate} from "react-router-dom";

const OnboardingFlow = () => {
    const [stage, setStage] = useState(1);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [companyIndustry, setCompanyIndustry] = useState("");
    const [companyMission, setCompanyMission] = useState("");
    const [topicListId, setTopicListId] = useState("APPRENTICESHIP_PROGRAM_IMPROVEMENT");
    const [question, setQuestion] = useState("");
    const [logo, setLogo] = useState<File | null>(null);
    const navigate = useNavigate()

    if (stage === 1) {
        return <Stage1Account onSubmit={(email, password, firstName, lastName) => {
            setEmail(email);
            setPassword(password);
            setFirstName(firstName);
            setLastName(lastName);
            setStage(2);
        }}/>
    }

    if (stage === 2) {
        return <Stage2Welcome onSubmit={() => {
            setStage(3);
        }} firstName={firstName}/>
    }

    if (stage === 3) {
        return <Stage3CompanyInfo onSubmit={(companyName, companyWebsite, companyIndustry, companyMission) => {
            setCompanyName(companyName);
            setCompanyWebsite(companyWebsite);
            setCompanyIndustry(companyIndustry);
            setCompanyMission(companyMission);
            setStage(4)
        }}/>
    }

    if (stage === 4) {
        return <Stage4Question companyName={companyName} onSubmit={(topicListId, question, logo) => {
            setTopicListId(topicListId);
            setQuestion(question);
            setLogo(logo || null);
            setStage(5)
        }} />
    }

    if (stage === 5) {
        return <Stage5ExtraInfo topicListId={topicListId} onSubmit={() => {
            setStage(8);
        }} />
    }

    if (stage === 8) {
        return <Stage8SettingUp email={email} password={password} firstName={firstName} lastName={lastName} companyName={companyName} companyWebsite={companyWebsite} companyIndustry={companyIndustry} companyMission={companyMission} topicListId={topicListId} question={question} logo={logo} onCompleted={(result) => {
            if (result.success) {
                navigate("/dashboard")
            } else {
                alert("Error setting up account: " + result.error + ". Please try again.")
                setStage(1)
            }
        }} />
    }

    return <></>
}

export default OnboardingFlow
