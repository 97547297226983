import React, { FC } from 'react';
import { Row } from 'atoms/Layout';
import OnboardingLayout from 'molecules/OnboardingLayout';
import Calendly from 'atoms/Calendly';
import CEOImage from 'atoms/CEOImage';
import { Title } from 'atoms/Typography';

const ScheduleCEOMeeting: FC = () => {
    return <OnboardingLayout marketingTitle="You'll find out how the following works:">
        <Row center gap="large">
            <CEOImage />
            <Title>Schedule an intro to Yello with<br/> Mohsin, our CEO</Title>
        </Row>
        <Calendly />
    </OnboardingLayout>
}

export default ScheduleCEOMeeting;
