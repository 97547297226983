import React, { FC } from 'react';
import { Row } from '../atoms/Layout';
import Panel from '../atoms/Panel';
import { Label, Title } from '../atoms/Typography';
import { VerticalSpace2 } from '../atoms/Space';
import { EmailInput, PasswordInput } from '../atoms/Input';
import Button from '../atoms/Button';
import { login } from '../api/users';
import { useNavigate } from 'react-router-dom';

const Login: FC = () => {
    const navigate = useNavigate()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState<string | null>(null)

    const onSubmit = () => {
        setLoading(true)
        login(email, password).then((user) => {
            if (user === null) {
                setError('Invalid email or password')
                setLoading(false)
                return
            }

            navigate('/dashboard/home')
        })
    }

    return <Row size="full" center>
        <Panel>
            <Title>Log in to your Yello account</Title>
            <VerticalSpace2 />
            <Label>Email</Label>
            <EmailInput value={email} onChange={setEmail} />
            <VerticalSpace2 />
            <Label>Password</Label>
            <PasswordInput value={password} onChange={setPassword} />
            <VerticalSpace2 />
            {error && <>
                <Label red>{error}</Label>
                <VerticalSpace2 />
            </>}
            <Row>
                <Button disabled={!email || !password} loading={loading} onClick={onSubmit}>Log in</Button>
            </Row>
        </Panel>
    </Row>
}

export default Login;
