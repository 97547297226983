import colors from 'atoms/colors';
import styled from 'styled-components';

const label = `
    font-family: WORK Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`

const body = `
    font-family: WORK Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: 0.15px;
`

const bodyBold = `
    font-family: WORK Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: 0.15px;
`

const subtitle = `
    font-family: WORK Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 138.889% */
    letter-spacing: 0.18px;
`

const title = `
    font-family: WORK Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    
    @media (max-width: 768px) {
        font-size: 20px;
    }
`

const mainTitle = `
    font-family: WORK Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 133.333% */
    max-width: 60%;
    
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const button = `
    color: ${colors.buttonText};
    text-align: center;
    font-family: WORK Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.342px; 
`;

const linkButton = `
    color: ${colors.brand};
    font-family: WORK Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    letter-spacing: 0.15px;
    cursor: pointer;
`;

const hint = `
    color: ${colors.hint};

    font-family: WORK Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.14px;
`

export const Label = styled.div<{ red?: boolean }>`
    ${label};
  
    color: ${props => props.red ? '#FF0000' : 'inherit'};
    display: inline;
`

export const LabelBold = styled.div`
    ${label};
    font-weight: 700;
    display: inline;
`;

export const Body = styled.div`
    ${body}
`

export const BodyBold = styled.div`
    ${bodyBold};
`;

export const Subtitle = styled.div`
    ${subtitle}
`

export const Title = styled.div<{ nowrap?: boolean }>`
    ${title};
    white-space: ${props => props.nowrap ? 'nowrap' : 'normal'};
`

export const MainTitle = styled.div<{ nowrap?: boolean }>`
    ${mainTitle};
    white-space: ${props => props.nowrap ? 'nowrap' : 'normal'};
`

export const Hint = styled.div`
    ${hint}
`

export const LinkButton = styled.div`
    ${linkButton};
    display: inline-block;
    text-decoration: underline;
`;


const typography = {
    body,
    subtitle,
    title,
    label,
    button,
    hint,
    mainTitle
}

export default typography;
