import React from 'react';
import styled from 'styled-components';
import colors from './colors';
import typography from './Typography';

const Tooltip = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 12px);
    padding: 8px;
    background: ${colors.panel};
    border: 1px solid ${colors.border};
    border-radius: 4px;
    ${typography.body};
    width: auto;
    min-width: 300px;
    max-width: 300px;
    visibility: visible;
    z-index: 100;
    color: ${colors.text};
`
export default Tooltip;
