import React, {FC, useState} from "react";
import Background from "./components/Background";
import {Column, Row} from "../atoms/Layout";
import {Label, MainTitle} from "../atoms/Typography";
import LightInput from "./components/LightInput";
import {HorizontalSpaceFlex1, VerticalSpace2, VerticalSpaceFlex1} from "../atoms/Space";
import colors from "../atoms/colors";
import nextArrow from "./components/nextArrow.png";
import LightSelect from "./components/SelectInput";

interface Props {
    onSubmit: (companyName: string, companyWebsite: string, companyIndustry: string, companyMission: string) => void
}

const industries = [
    'Commercial Real Estate',
    'Energy',
    'Fashion',
    'Finance',
    'Government',
    'Healthcare',
    'Hospitality & Leisure',
    'Law',
    'Manufacturing',
    'Marketing & Advertising',
    'Technology',
    'Other'
]

const Stage3CompanyInfo: FC<Props> = ({ onSubmit }) => {
    const [companyName, setCompanyName] = useState<string>('')
    const [companyWebsite, setCompanyWebsite] = useState<string>('')
    const [companyIndustry, setCompanyIndustry] = useState<string>('')
    const [companyMission, setCompanyMission] = useState<string>('')

    const submit = () => {
        onSubmit(companyName, companyWebsite, companyIndustry, companyMission)
    }

    return (
        <Background dark={false}>
            <Row hcenter>
                <Column gap="small" style={{maxWidth: 400, flexGrow: 1}}>
                    <MainTitle style={{ lineHeight: "30px", fontWeight: 500, fontSize: "20px", maxWidth: "50%"}}>Let's get you set up.</MainTitle>
                    <Label style={{ color: "black"}}>What's the company name?</Label>
                    <LightInput onChange={setCompanyName} value={companyName} />
                    <Label style={{ color: "black"}}>... and company website? <span style={{ fontWeight: 200}}>Copy/paste URL</span></Label>
                    <LightInput onChange={setCompanyWebsite} value={companyWebsite} />
                    <Label style={{ color: "black"}}>Choose your Sector.</Label>
                    <LightSelect options={industries.map(it => ({ value: it, label: it }))} onChange={setCompanyIndustry} value={companyIndustry} />
                    <Label style={{ color: "black"}}>What's your company's mission?</Label>
                    <LightInput onChange={setCompanyMission} value={companyMission} />
                    <VerticalSpaceFlex1 />
                    <VerticalSpace2 />
                    <Row vcenter>
                        <Label style={{ color: "black"}}>Step 1 of 3</Label>
                        <HorizontalSpaceFlex1 />
                        <button style={{padding: "0 32px", border: "none", borderRadius: 8, background: colors.brand, color: "white"}} onClick={submit}>
                            <img src={nextArrow} alt="Next" width="48" height="48" />
                        </button>
                    </Row>
                </Column>
            </Row>
        </Background>
    );
};

export default Stage3CompanyInfo;