import React, { FC } from 'react';
import { ThemeProps } from '../theme';
import YelloLogo from '../atoms/YelloLogo';
import { Hint, InlineLink } from '../atoms/typography';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const Footer: FC<ThemeProps> = ({ feedbackTheme }) => {
    return <Container>
        <YelloLogo feedbackTheme={feedbackTheme} />
        <Hint center feedbackTheme={feedbackTheme}>By continuing, you agree to our <InlineLink feedbackTheme={feedbackTheme} href="/terms-of-service" target="_blank">Terms of Service</InlineLink> and <InlineLink feedbackTheme={feedbackTheme} href="/privacy-policy" target="_blank">Privacy Policy</InlineLink></Hint>
    </Container>
}

export default Footer;
