import React, { FC } from 'react';
import SentimentCard from '../atoms/SentimentCard';
import { HorizontalSpaceFlex1, VerticalSpaceFlex1 } from 'atoms/Space';
import { Body, Title } from 'atoms/Typography';
import { Channel } from 'api/channels';
import { Column, Row } from 'atoms/Layout';
import { useNavigate } from 'react-router-dom';
import { ChatBubbleLeftEllipsis, UserCircle } from 'atoms/Icon';
import { useAppContext } from 'context/AppContext';
import { SENTIMENT_NAMES, toSentiment } from 'model/feedback';
import ClipboardIcon from '../molecules/ClipboardIcon';
import styled from 'styled-components';
import QRCodeIcon from '../molecules/QRCodeIcon';

interface Props {
    channel: Channel;
}

const RowWithMaxWidth = styled(Row)`
    max-width: 330px;
`;

const NormalWeight = styled.span`
    font-weight: normal;
`;

const ChannelCard: FC<Props> = ({ channel }) => {
    const { feedback, space } = useAppContext()
    const navigate = useNavigate()
    const relatedFeedback = feedback.value?.filter(f => f.channelId === channel.id) ?? []

    const averageSentimentScore = relatedFeedback.length ? relatedFeedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / relatedFeedback.length : 0
    const sentiment = relatedFeedback.length ? toSentiment(averageSentimentScore) : undefined

    const goToFeedback = () => {
        navigate(`/dashboard/insights/${channel.id}`)
    }

    return <SentimentCard onClick={goToFeedback} sentiment={sentiment}>
        <Row gap="small">
            <HorizontalSpaceFlex1 />
            <ClipboardIcon content={`https://app.itsyello.ai/feedback/${space.value?.id || 'channel'}/${channel.id}`}>Copy Feedback Page URL</ClipboardIcon>
            <QRCodeIcon url={`/api/channel/${channel.id}/qr`} />
        </Row>
        <RowWithMaxWidth>
            <Body>{channel.name}</Body>
        </RowWithMaxWidth>
        <Row>
            <Title>
                <NormalWeight>Q</NormalWeight> {channel.prompt}
            </Title>
        </Row>
        <VerticalSpaceFlex1 />
        <Row>
            <Column size="full" gap="small">
                <Row vcenter gap="small">
                    <ChatBubbleLeftEllipsis />
                    <Body>{relatedFeedback.length} responses</Body>
                </Row>
                <Row vcenter gap="small">
                    <UserCircle/>
                    <Body>{sentiment ? `${SENTIMENT_NAMES[sentiment]} sentiment` : 'No sentiment analysis yet'}</Body>
                </Row>
            </Column>
        </Row>

    </SentimentCard>
}

export default ChannelCard;
