
import React, {FC, useEffect, useMemo} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Column, Row } from 'atoms/Layout';
import ChannelSelect from 'organisms/ChannelSelect';
import InsightThemes from 'templates/InsightThemes';
import InsightScores from 'templates/InsightScores';
import { HorizontalSpaceFlex1 } from 'atoms/Space';
import {useAppContext} from "../../context/AppContext";
import Loading from "../../atoms/Loading";
import Panel from "../../atoms/Panel";
import {Subtitle} from "../../atoms/Typography";
import {streamInsights} from "../../api/insights";
import {Insights as ModelInsights} from "../../api/insights";
import Button from "../../atoms/Button";
import {unparse} from "papaparse";
import {TopicList} from "../../api/topicLists";

const exportCsv = (insights: ModelInsights, topicList: TopicList) => {
    const rows = [
        { type: 'Action Plan', key: '', insight: insights.actionPlan }
    ]

    insights.themes.forEach((theme, index) => {
        rows.push({ type: 'Theme', key: theme.topic?.name || '', insight: theme.content })
    })

    insights.scores.forEach((score, index) => {
        if (score.content === '') {
            return
        }
        const topic = topicList.scores.find(s => s.id === score.id)
        rows.push({ type: 'Score', key: topic?.name || '', insight: score.content })
    })

    const csv = unparse(rows)

    const blob = new Blob([csv], { type: 'text/csv' })

    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = 'insights.csv'
    a.click()
}

const Insights: FC = () => {
    const { channelId } = useParams();
    const { channels, topicLists, feedback } = useAppContext();
    const [insights, setInsights] = React.useState<ModelInsights | undefined>();
    const [refreshIndex, setRefreshIndex] = React.useState(0);

    const onInsights = useMemo(() => (newInsights: ModelInsights) => {
        console.log(newInsights)
        setInsights(newInsights);
    }, [setInsights])

    const onExport = () => {
        if (!insights || !channels.value || !topicLists.value) {
            return;
        }
        const chan = channels.value.find(c => c.id === channelId)
        const topicList = topicLists.value.find(tl => tl.id === chan?.topicListId)
        if (!chan || !topicList) {
            return;
        }
        exportCsv(insights, topicList)
    }

    useEffect(() => {
        setInsights(undefined);
        const { stopStream } = streamInsights(onInsights, channelId)

        return () => {
            stopStream();
        }
    }, [channelId, refreshIndex])

    const navigate = useNavigate();

    const changeChannel = (channelId?: string) => {
        if (!channelId) {
            navigate('/dashboard/insights')
            return
        }

        navigate(`/dashboard/insights/${channelId}`)
    }

    useEffect(() => {
        void channels.load();
        void topicLists.load();
        void feedback.load();
    }, []);

    useEffect(() => {
        if (!channels.value) {
            return;
        }

        if (!channelId) {
            changeChannel(channels.value[0].id);
            return;
        }
    }, [channels.value, channelId])

    if (channels.loading || topicLists.loading || feedback.loading) {
        return <Loading />
    }

    if (feedback.value?.filter(f => f.channelId === channelId).length === 0) {
        return <Column size="full" gap="large">
            <Row>
                <Column>
                    <ChannelSelect channelId={channelId} onChange={changeChannel}/>
                </Column>
                <HorizontalSpaceFlex1 />
            </Row>
            <Row>
                <Panel>
                    <Subtitle>A bit quiet, isn't it? <br/><br/> Collect feedback to bring this page to life.  Once the responses roll in, so will your insights! <br/><br/> For tips on gathering feedback, visit the How-To Hub.</Subtitle>
                </Panel>
            </Row>
        </Column>
    }

    return <Column size="full" gap="large">
        <Row>
            <Column>
                <ChannelSelect channelId={channelId} onChange={changeChannel}/>
            </Column>
            <HorizontalSpaceFlex1 />
            <Button disabled={!insights?.finished} onClick={onExport}>
                Export (CSV)
            </Button>
        </Row>
        <Row gap="large">
            <InsightThemes insights={insights} onRefresh={() => setRefreshIndex(i => i + 1)} />
            <InsightScores channelId={channelId} insights={insights} />
        </Row>
    </Column>
}

export default Insights;
