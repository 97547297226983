import {Column, Row} from "../atoms/Layout";
import {VerticalSpace1, VerticalSpace4, VerticalSpaceFlex1} from "../atoms/Space";
import React, {FC} from "react";
import Background from "./components/Background";
import {ReactComponent as Logo} from "./components/Logo.svg";
import {Body, Subtitle} from "../atoms/Typography";
import colors from "../atoms/colors";

interface Props {
    firstName: string
    onSubmit: () => void
}

const Stage2Welcome: FC<Props> = ({ onSubmit, firstName }) => {
    return (
        <Background dark>
            <Row center>
                <Column gap="small" style={{ maxWidth: 400, flexGrow: 1 }}>
                    <Row center>
                        <Logo width="80" />
                    </Row>
                    <VerticalSpaceFlex1 />
                    <Row center>
                        <Subtitle>Hey {firstName}, welcome to Yello!</Subtitle>
                    </Row>
                    <VerticalSpace1 />
                    <Row center>
                        <Body style={{ textAlign: "center" }}>Let's set up your AI voice link and get you capturing valuable insights.</Body>
                    </Row>
                    <VerticalSpace4 />
                    <Row center>
                        <Subtitle style={{ textAlign: "center", maxWidth: 160 }}>You'll be done in 60 seconds.</Subtitle>
                    </Row>
                    <VerticalSpace4 />
                    <Row center>
                        <button style={{padding: "16px 32px", border: "none", borderRadius: 8}} onClick={onSubmit}>
                            <Body style={{ textAlign: "center", color: colors.brand }}>Get started</Body>
                        </button>
                    </Row>

                </Column>
            </Row>
        </Background>
    )
}

export default Stage2Welcome