import axios from "axios";

export interface TopicListScore {
    id: string;
    name: string;
    topics: string[];
}

export interface TopicList {
    id: string;
    purpose: string;
    scores: TopicListScore[];
}

export const getTopicLists = async (): Promise<TopicList[]> => {
    const res = await axios.get(`/api/topicLists`)
    return res.data
}
