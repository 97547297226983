import styled from 'styled-components';

const Navigation = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
    
    @media (max-width: 768px) {
        display: none;
    }
`

export default Navigation;
