import React, { FC } from 'react';
import LogoSrc from './Logo.svg';
import colors from "../colors";

interface Props {
    size?: 'small' | 'medium' | 'large';
}

const Logo: FC<Props> = ({ size }) => {
    if (size === 'small') {
        return <img src={LogoSrc} alt="Yello Logo" width="52" height="26" style={{ transform: 'translate(0, 3px)'}} />
    }
    if (!size || size === 'medium') {
        return <img src={LogoSrc} alt="Yello Logo" width="104" height="52"/>
    }
    return <img src={LogoSrc} alt="Yello Logo" width="208" height="104"/>
}

export default Logo;
