import {FC, useState} from "react";
import Background from "./components/Background";
import {Column, Row} from "../atoms/Layout";
import {ReactComponent as Logo} from "./components/Logo.svg";
import {Body, Subtitle} from "../atoms/Typography";
import {VerticalSpace2, VerticalSpaceFlex1} from "../atoms/Space";
import DarkInput from "./components/DarkInput";
import NextIcon from "./components/next.png";

interface Props {
    onSubmit: (email: string, password: string, firstName: string, lastName: string) => void
}

const linkStyles = { color: "white", textDecoration: "none", fontWeight: 600};

const Stage1Account: FC<Props> = ({ onSubmit }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");



    const canSubmit = email.includes("@") && password.length >= 6 && password === confirmPassword && firstName.length > 0 && lastName.length > 0;

    return (
        <Background dark>
            <Row center>
                <Column gap="small" style={{ maxWidth: 400, flexGrow: 1 }}>
                    <Row center>
                        <Logo width="80" />
                    </Row>
                    <VerticalSpaceFlex1 />
                    <Row center>
                        <Subtitle>Join with your work email</Subtitle>
                    </Row>
                    <Row center>
                        <Body style={{ textAlign: "center" }}>If you can't use your work email, use your personal.</Body>
                    </Row>
                    <VerticalSpace2 />
                    <DarkInput placeholder="Email" logo="email" value={email} onChange={setEmail} />
                    <DarkInput hide placeholder="Password" logo="lock" value={password} onChange={setPassword} />
                    <DarkInput hide placeholder="Confirm password" logo="checkmark" value={confirmPassword} onChange={setConfirmPassword} />
                    <DarkInput placeholder="First name" value={firstName} onChange={setFirstName} />
                    <DarkInput placeholder="Last name" value={lastName} onChange={setLastName} />
                    <VerticalSpace2 />
                    <Row center>
                        <Body style={{ textAlign: "center", maxWidth: 280 }}>By continuing, you agree to Yello's <a href="/" style={linkStyles}>Terms of Service</a> & <a href="/" style={linkStyles}>Privacy Policy</a></Body>
                    </Row>
                    <VerticalSpace2 />
                    <Row center>
                        <img src={NextIcon} alt="Next" style={{cursor: "pointer", opacity: canSubmit ? 1 : 0.5}} width="48" height="48" onClick={() => {
                            if (canSubmit) {
                                onSubmit(email, password, firstName, lastName);
                            }
                        }}/>

                    </Row>

                </Column>
            </Row>
        </Background>
    )
}

export default Stage1Account
