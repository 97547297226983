import styled from 'styled-components';
import typography from './Typography';
import React, { FC } from 'react';

const StyledSelect = styled.select`
    ${typography.body};
    color: currentColor;
    background: transparent;
    border: 1px solid;
    border-radius: 4px;
    padding: 8px;
  
    & option {
        ${typography.body};
    }
`;

export interface SelectOption {
    value: string;
    label: string;
}

interface Props {
    options: SelectOption[]
    value: string;
    onChange?: (value: string) => void;
}

const SelectInput: FC<Props> = ({ options, value, onChange }) => {
    return (
        <StyledSelect value={value} onChange={event => onChange?.(event.target.value)}>
            {options.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </StyledSelect>
    );
}

export default SelectInput;
