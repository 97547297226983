import React, { FC } from 'react';
import colors from './colors';
import styled from 'styled-components';
import typography from './Typography';
import Loading from './Loading';

interface Props {
    name?: string;
}

const AvatarCircle = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: ${colors.brand};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    flex-shrink: 0;
  
    ${typography.button};
    color: ${colors.textLight};
`;

const Avatar: FC<Props> = ({ name }) => {
    if (!name) {
        return <Loading size="small" />;
    }

    return <AvatarCircle>{name.charAt(0).toUpperCase()}</AvatarCircle>;
}

export default Avatar;
