import styled from 'styled-components'
import colors from './colors';

const Panel = styled.div<{ hidden?: boolean }>`
  background-color: ${colors.panel};
  border-radius: 20px;
  border: 4px solid ${colors.brand};
  padding: ${props => props.hidden ? '35px 0px' : '35px'};
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 35px 0 rgba(206, 206, 206, 0.25);
  width: ${props => props.hidden ? '0px' : 'initial'};
  visibility: ${props => props.hidden ? 'hidden' : 'visible'};
  
  transition: width 0.5s ease-out, visibility 0.5s ease-out, padding 0.5s ease-out;
  
  @media (max-width: 768px) {
    padding: ${props => props.hidden ? '20px 0px' : '20px'};
  }
`

export default Panel;