import styled from "styled-components";
import colors from "../../atoms/colors";

const Background = styled.div<{ dark: boolean }>`
    width: 100vw;
    height: 100vh;
    border: 2px solid ${colors.brand};
    color: ${props => props.dark ? colors.background : colors.brand};
    border-radius: 16px;
    padding: 32px;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    background: ${props => props.dark ? colors.brand : colors.background};
`;

export default Background;