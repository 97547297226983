import React, {FC, useEffect, useState} from 'react';
import { useAppContext } from '../context/AppContext';
import SentimentCard from '../atoms/SentimentCard';
import { VerticalSpaceFlex1 } from '../atoms/Space';
import {Label, Title} from '../atoms/Typography';
import { TextInput } from '../atoms/Input';
import { Row } from '../atoms/Layout';
import Button from '../atoms/Button';
import { createChannel } from '../api/channels';
import SelectInput from '../atoms/SelectInput';

const AddChannelCard: FC = () => {
    const channels = useAppContext().channels
    const topicLists = useAppContext().topicLists
    const [newChannelName, setNewChannelName] = useState<string>("")
    const [topicListId, setTopicListId] = useState<string>("")
    const [prompt, setPrompt] = useState<string>("")
    const [saving, setSaving] = useState<boolean>(false)

    const duplicateName = channels.value?.find(channel => channel.name === newChannelName)
    const invalidData = !channels.value || newChannelName.length === 0 || !!duplicateName || !topicListId || !prompt

    const topicListOptions = [
        { value: "", label: "Select one"},
        ...topicLists.value?.map(it => ({ value: it.id, label: it.purpose })) || []
    ]

    useEffect(() => {
        void topicLists.load()
    }, []);

    const onSubmit = async () => {
        if (!channels.value) return;

        setSaving(true)
        const channel = await createChannel(newChannelName, topicListId, prompt);
        setSaving(false)
        setNewChannelName("")
        setPrompt("")
        setTopicListId("")
        channels.setValue([...channels.value, channel])
    }

    return <SentimentCard>
        <VerticalSpaceFlex1 />
        <Row>
            <Title>Add Channel</Title>
        </Row>
        <Row gap="small" vcenter>
            <Label>Name your Channel</Label>
        </Row>
        <TextInput value={newChannelName} onChange={setNewChannelName} max={64} error={!!duplicateName}/>
        <Row gap="small" vcenter>
            <Label>What would you like to ask?</Label>
        </Row>
        <TextInput value={prompt} onChange={setPrompt} max={255}/>
        <Row gap="small" vcenter>
            <Label>What do you want to achieve?</Label>
        </Row>
        <SelectInput options={topicListOptions} value={topicListId} onChange={setTopicListId}/>
        <Button onClick={onSubmit}
                disabled={invalidData}
                loading={saving}
                light>Add</Button>
    </SentimentCard>
}

export default AddChannelCard;
