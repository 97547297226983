import {FC, useEffect, useState} from "react";
import styled from "styled-components";
import colors from "../atoms/colors";
import close from "../atoms/close.png";
import {Body} from "../atoms/Typography";
import {Link} from "../atoms/Icon";

const Container = styled.div`
    background-color: ${colors.panel};
    border-radius: 20px;
    border: 2px solid ${colors.brand};
    padding: 35px;
    position: relative;
    box-shadow: 0 4px 35px 0 rgba(206, 206, 206, 0.25);
`

const CloseButton = styled.img`
    position: absolute;
    top: -8px;
    left: -8px;
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const WelcomeMessage: FC = () => {
    const [closed, setClosed] = useState(true);

    useEffect(() => {
        // Get the closed state from local storage
        const closedState = localStorage.getItem('welcomeMessageClosed');
        setClosed(closedState === 'true');
    })

    const handleClose = () => {
        // Save the closed state to local storage
        localStorage.setItem('welcomeMessageClosed', 'true');
        setClosed(true);
    }

    if (closed) {
        return null;
    }

    return (
        <Container>
            <CloseButton src={close} onClick={handleClose} />
            <Body><span style={{ color: colors.brand }}>Your AI-powered yello voice link is ready! </span>
            Just copy the <Link width={24} height={24} /> icon below and paste it wherever needed - For more info, check out the How-to-Hub
            / FAQs in the menu.</Body>
        </Container>
    );
}

export default WelcomeMessage