import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import colors from 'atoms/colors';
import typography from 'atoms/Typography';
import Loading from 'atoms/Loading';

const StyledButtonRegular = styled.button`
    background: ${colors.brand};
    ${typography.button};
    color: ${colors.textLight};
    display: inline-flex;
    padding: 15px 33px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 80px;
    border: 0;
    cursor: pointer;
    white-space: nowrap;
  
    &:disabled {
      opacity: 0.5;
    }
`

const StyledButtonLight = styled.button`
    background: ${colors.buttonLight};
    ${typography.button};
    color: ${colors.text};
    display: inline-flex;
    padding: 15px 33px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 80px;
    border: 0;
    cursor: pointer;
    white-space: nowrap;
  
    &:disabled {
      opacity: 0.5;
    }
`

interface Props {
    onClick?: () => void;
    children?: ReactNode;
    disabled?: boolean;
    loading?: boolean;
    light?: boolean;
}

const Button: FC<Props> = ({ children, onClick, disabled, loading, light }) => {
    const StyledButton = light ? StyledButtonLight : StyledButtonRegular;

    return <StyledButton disabled={disabled || loading} onClick={onClick}>
        { loading && <Loading /> }
        {children}
    </StyledButton>
}

export default Button;
