import React, { FC, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import ChannelFeedback from '../../feedbackPage/pages/ChannelFeedback';

const RedirectToIndex: FC = () => {
    useEffect(() => {
        window.location.href = '/';
    });

    return null;
}

const FeedbackContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Feedback: FC = () => {
    return <FeedbackContainer>
        <Routes>
            {/*<Route path="/tile/:id" element={<TileFeedback />} />*/}
            <Route path="/:spaceId/:id" element={<ChannelFeedback />} />
            <Route path="*" element={<RedirectToIndex />} />
        </Routes>
    </FeedbackContainer>
}

export default Feedback;
