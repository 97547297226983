import styled from "styled-components";
import EmailIcon from "./email.png";
import LockIcon from "./lock.png";
import CheckmarkIcon from "./checkmark.png";

const StyledInput = styled.input`
    outline: none;
    border: none;
    flex-grow: 1;
    flex-basis: 0;
`;

const Container = styled.div`
    height: 48px;
    background: white;
    border: 2px solid #F3F3F3;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    gap: 16px;
`;

const LogoContainer = styled.div`
    width: 48px;
    height: 44px;
    background: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type LogoType = "email" | "lock" | "checkmark"

interface Props {
    placeholder: string
    logo?: LogoType
    value?: string
    onChange?: (value: string) => void
    hide?: boolean
}

const DarkInput = ({placeholder, logo, value, onChange, hide}: Props) => {
    return (
        <Container>
            { logo && <LogoContainer>
                { logo === "email" && <img src={EmailIcon} alt="" width="24" height="24" /> }
                { logo === "lock" && <img src={LockIcon} alt="" width="24" height="24" /> }
                { logo === "checkmark" && <img src={CheckmarkIcon} alt="" width="24" height="24" />}

            </LogoContainer> }
            { !logo && <div style={{width: "0px"}}></div> }
            <StyledInput placeholder={placeholder} value={value} onChange={e => onChange?.(e.target.value)} type={hide ? 'password' : 'text'}/>
        </Container>
    )
}

export default DarkInput
