import {FC, useEffect} from "react";
import Background from "./components/Background";
import {Column, Row} from "../atoms/Layout";
import {ReactComponent as Logo} from "./components/Logo.svg";
import {Subtitle} from "../atoms/Typography";
import {VerticalSpaceFlex1} from "../atoms/Space";
import sparkle from "./components/sparkle.png";
import {register} from "../api/users";
import {updateSpace} from "../api/spaces";
import {FeedbackPageAnonymizeSetting, FeedbackPageStyle, updateFeedbackPage} from "../api/feedbackPage";
import colors from "../atoms/colors";
import {createChannel} from "../api/channels";

interface Props {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    companyName: string;
    companyWebsite: string;
    companyIndustry: string;
    companyMission: string;
    topicListId: string;
    question: string;
    logo: File | null;
    onCompleted: (result: Result) => void;
}

interface Result {
    success: boolean;
    error?: string;
}

const setUpAccount: (props: Props) => Promise<Result> = async props => {
    try {
        const authResult = await register({
            email: props.email,
            password: props.password,
            name: `${props.firstName} ${props.lastName}`,
            spaceName: props.companyName
        });

        if (!authResult) {
            return {success: false, error: "That email address is already in use"};
        }

        await updateSpace({
            name: props.companyName,
            welcomeEmailSent: true,
            contextValues: {
                companyName: props.companyName,
                companyWebsite: props.companyWebsite,
                companyIndustry: props.companyIndustry,
                companyMission: props.companyMission,
            }
        })

        await updateFeedbackPage(
            FeedbackPageStyle.LIGHT,
            colors.brand,
            false,
            FeedbackPageAnonymizeSetting.NEVER,
            false,
            props.logo || undefined);

        await createChannel("Feedback", props.topicListId, props.question)

        return {success: true};
    } catch (e: any) {
        return {success: false, error: e.message};
    }
}

const Stage8SettingUp: FC<Props> = props => {
    useEffect(() => {
        setUpAccount(props).then(props.onCompleted);
    }, [props]);

    return <Background dark>
        <Row hcenter style={{ height: "calc(100vh - 70px)"}}>
            <Column center>
                <Logo width="80" />
                <VerticalSpaceFlex1 />
                <img src={sparkle} alt="Sparkle" width="100" />
                <VerticalSpaceFlex1 />
                <Subtitle style={{ whiteSpace: "nowrap"}}>Setting Up...</Subtitle>
            </Column>
        </Row>
    </Background>
}

export default Stage8SettingUp;