
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Row } from '../../atoms/Layout';
import { FeedbackTheme } from '../theme';

interface Props {
    value: number | null;
    onChange: (value: number | null) => void;
    theme: FeedbackTheme;
}

const RangeTrack = styled.div`
    position: relative;
    height: 4px;
    border-radius: 2px;
    background: #868181;
    width: 300px;
`

const RangeThumb = styled.div<{value: number, feedbackTheme: FeedbackTheme}>`
    position: absolute;
    top: -40px;
    left: calc(${props => props.value * 10}% - 16px);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${props => props.feedbackTheme.colors.primary};
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-family: Work Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    z-index: 1;
  
    &:after {
      content: '';
      position: absolute;
      top: 7px;
      left: 25%;
      width: 16px;
      height: 16px;
      background: ${props => props.feedbackTheme.colors.primary};
      transform: rotate(45deg) translate(50%, 50%);
      z-index: -1
    }
`

const PromoterScoreSlider: FC<Props> = ({ value, onChange, theme }) => {
    const trackRef = React.useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = React.useState(false);

    const handleMouseMove = (event: MouseEvent, overrideDragging?: boolean) => {
        if (!dragging && !overrideDragging) return;
        const rect = trackRef.current?.getBoundingClientRect();
        if (rect) {
            let newValue = Math.round(((event.clientX - rect.left) / rect.width) * 10);
            newValue = Math.max(0, Math.min(10, newValue)); // Ensure within range
            onChange(newValue);
        }
        event.preventDefault();
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setDragging(true);
        handleMouseMove(event.nativeEvent, true);
        event.preventDefault()
    };

    const handleMouseUp = (event: MouseEvent) => {
        setDragging(false);
        handleMouseMove(event);
    };

    useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    return <Row vcenter nowrap gap="small" onMouseDown={handleMouseDown} >
        <RangeTrack ref={trackRef}>
            <RangeThumb value={value ?? 5} onMouseDown={handleMouseDown} feedbackTheme={theme}>
                {value ?? 5}
            </RangeThumb>
        </RangeTrack>
    </Row>
}

export default PromoterScoreSlider;
