import React, {FC, useState} from "react";
import {Label, MainTitle} from "../atoms/Typography";
import {Column, Row} from "../atoms/Layout";
import Background from "./components/Background";
import LightInput from "./components/LightInput";
import paperclip from "./components/paperclip.png";
import {HorizontalSpaceFlex1} from "../atoms/Space";
import colors from "../atoms/colors";
import nextArrow from "./components/nextArrow.png";

interface Props {
    topicListId: string;
    onSubmit: () => void;
}

const webpageQuery = (topicListId: string) => {
    switch (topicListId) {
        case 'CUSTOMER_SERVICE':
            return "Does your customer support service have a web page?"
        case 'ONLINE_COURSE_FEEDBACK':
            return "Does your online course have a public web page?"
        case 'WEBINAR_FEEDBACK':
            return "Does your webinar have a public web page?"
        case 'MARKETING_CAMPAIGN_FEEDBACK':
            return "Does your brand have a web page?"
        case 'SALES_PRESENTATIONS_IMPROVEMENT':
            return "Does your company have a web page?"
        case 'CONSULTING_SERVICES_IMPROVEMENT':
            return "Does your company have a web page?"
        case 'UX_SERVICE_TEST':
            return "Does your service have a web page?"
        case 'EMPLOYEE_SATISFACTION_IMPROVEMENT':
            return "Does your company have a web page describing your team or company values?"
        case 'CUSTOMER_STORE_EXPERIENCE':
            return "Does your store have a web page?"
        case 'CONFERENCE_FEEDBACK':
            return "Does your conference have a web page?"
        case 'GRADUATE_PROGRAM_IMPROVEMENT':
            return "Does your programme have a web page?"
        case 'APPRENTICESHIP_PROGRAM_IMPROVEMENT':
            return "Does your programme have a web page?"
        case 'INCUBATOR_ACCELERATOR_IMPROVEMENT':
            return "Does your programme have a web page?"
        case 'SOFTWARE_TOOL_FEEDBACK':
            return "Does your software tool or service have a landing page?"
        case 'IT_SERVICES_IMPROVEMENT':
            return "Does your company have a web page?"
        default:
            return "Does your company have a web page?"
    }
}

const Stage5ExtraInfo: FC<Props> = ({topicListId, onSubmit}) => {
    const [webpage, setWebpage] = useState<string>("")
    const [attachmentCount, setAttachmentCount] = useState<number>(0)
    const [extraDetails, setExtraDetails] = useState<string>("")

    return (
        <Background dark={false}>
            <Row hcenter>
                <Column gap="small" style={{maxWidth: 400, flexGrow: 1}}>
                    <MainTitle style={{ lineHeight: "30px", fontWeight: 500, fontSize: "20px", maxWidth: "100%"}}>Just one more question to get the AI working for you.</MainTitle>
                    <Label style={{ color: "black"}}>{webpageQuery(topicListId)}</Label>
                    <Label style={{ color: "black", fontWeight: 300}}>Paste below.</Label>
                    <LightInput value={webpage} onChange={setWebpage} />
                    <Label style={{ color: "black"}}>Just type, paste or attach any extra details.</Label>
                    <Row vcenter>
                        <LightInput value={extraDetails} onChange={setExtraDetails}/>
                        <input type="file" style={{display: "none"}}
                               onChange={e => setAttachmentCount(attachmentCount + (e.target.files?.length || 0))}/>
                        <img src={paperclip} alt="Upload logo" width="64" height="64" onClick={() => {
                            const input = document.querySelector('input[type="file"]')
                            if (input) {
                                (input as any).click()
                            }
                        }}/>
                    </Row>
                    <Row>
                        <Label style={{ color: "black", fontSize: "8px", marginLeft: 138}}>{attachmentCount} attachment(s)</Label>
                    </Row>
                    <Row vcenter>
                        <Label style={{ color: "black"}}>Step 3 of 3</Label>
                        <HorizontalSpaceFlex1 />
                        <button style={{padding: "0 32px", border: "none", borderRadius: 8, background: colors.brand, color: "white"}} onClick={onSubmit}>
                            <img src={nextArrow} alt="Next" width="48" height="48" />
                        </button>
                    </Row>
                </Column>
            </Row>
        </Background>
    )
}

export default Stage5ExtraInfo;