import React, { FC } from 'react';
import { FeedbackResponse } from 'api/feedback';
import { Column, Row } from 'atoms/Layout';
import {Body, Subtitle, Title} from 'atoms/Typography';
import {HorizontalSpaceFlex1, VerticalSpace1, VerticalSpace2} from "../atoms/Space";
import styled from "styled-components";
import colors from "../atoms/colors";
import {ChevronDown, ChevronUp} from "../atoms/Icon";

interface Props {
    feedback: FeedbackResponse[];
    title: string;
    score?: number;
    explanation?: string;
}

const toScore = (averageSentiment: number): number => {
    const normalizedSentiment = (averageSentiment + 1) / 2;
    const value = Math.max(0, Math.min(1, normalizedSentiment)); // Equivalent to clamp
    return 10 * value * value * (3 - 2 * value);
}


const MainScoreText = styled(Title)`
    color: ${colors.brand};
`;

const ScoreDisplay: FC<{ score: number }> = ({ score }) => {
    return <Row nowrap style={{ alignItems: 'flex-end'}}>
        <MainScoreText>{score.toFixed(1)}</MainScoreText>
        <Subtitle>/10</Subtitle>
    </Row>
}

const InsightScore: FC<Props> = ({ feedback, title, score, explanation }) => {
    let [open, setOpen] = React.useState(false);

    let averageSentiment = feedback.length ? feedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / feedback.length : 0;

    if (score) {
        averageSentiment = score;
    }


    return <Column>
        <div style={{ minWidth: 260}} />
        <Row size="full" nowrap gap="medium" vcenter onClick={() => setOpen(!open)}>
            <Title>{title}</Title>
            { open ? <ChevronUp width="24" height="24" /> : <ChevronDown width="24" height="24" /> }
            <HorizontalSpaceFlex1 />
            <ScoreDisplay score={toScore(averageSentiment)} />
        </Row>

        {open && <>
            <VerticalSpace2 />
            <Row>
                { feedback.length > 0 && <Body style={{ color: colors.hint }}>Based on <strong style={{ color: colors.text }}>{feedback.length} responses</strong> and augmented persona data, benchmarked by your
                    programme module</Body> }
                { feedback.length === 0 && <Body style={{ color: colors.hint }}>No feedback available</Body> }
            </Row>
            <VerticalSpace2 />
            <Row>
                {explanation}
            </Row>

        </>}

    </Column>

}

export default InsightScore;
