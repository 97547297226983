


export default {
    text: '#181C24',
    textLight: 'white',
    border: '#181C24',
    borderSecondary: '#C1C1C4',
    background: '#F8F8FF',
    buttonLight: '#fff',
    buttonText: '#181C24',
    hint: '#A1A1A4',
    panel: '#fff',
    brand: '#9900ff',
    brandLight: '#EDE9FE',
    secondary: '#E4E3FA',
    modalBackground: 'rgba(6, 0, 24, 0.71)',
}
