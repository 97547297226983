import React, { FC } from 'react';
import { Column } from '../../atoms/Layout';
import ChannelCards from '../../templates/ChannelCards';
import WelcomeMessage from "../../molecules/WelcomeMessage";

const Home: FC = () => {
    return <Column size="full" gap="large">
        <WelcomeMessage />
        <ChannelCards />
    </Column>
}

export default Home;
