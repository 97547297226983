import styled from 'styled-components';
import colors from './colors';
import typography from './Typography';

const Pill = styled.div<{ active?: boolean, onClick?: any }>`
    ${typography.label};
    display: inline-flex;
    padding: 1px 5px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 15px;
    border: 1px solid ${props => props.active ? colors.brand : colors.secondary};
    background: ${props => props.active ? colors.brand : colors.secondary};
    color: ${props => props.active ? colors.textLight : colors.text};
    cursor: ${props => props.onClick ? 'pointer' : 'default'};
`;

export default Pill;
