import styled from 'styled-components';
import React, { FC } from 'react';
import { FeedbackTheme } from '../theme';
import formatDuration from '../utils/formatDuration';

const Container = styled.div<{ feedbackTheme: FeedbackTheme, active: boolean }>`
    width: 240px;
    padding: 12px;
    height: 72px;
    border: 1px solid ${props => props.feedbackTheme.colors.foreground};
    background-color: ${props => props.active ? props.feedbackTheme.colors.foreground : props.feedbackTheme.colors.background};
    border-radius: 36px;
    display: flex;
    gap: 12px;
    align-items: center;
  
    transition: background-color 0.2s ease-in-out;
`;

const PlayPauseButton = styled.button<{ feedbackTheme: FeedbackTheme }>`
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: none;
    background-color: ${props => props.feedbackTheme.colors.primary};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &:hover {
        background-color: ${props => props.feedbackTheme.colors.primaryHover};
    }
`;

const PlayIcon: FC = () => {
    return <svg width="14" height="16" viewBox="-1 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path id="Path" d="M1.1816 0.61582L13.4162 7.49831C13.5946 7.59902 13.7043 7.78342 13.7043 7.98278C13.7043 8.18213 13.5946 8.36653 13.4162 8.46725L1.1816 15.3497C1.00116 15.4513 0.778136 15.4518 0.597193 15.3511C0.416251 15.2504 0.305137 15.0639 0.306036 14.8625V1.10085C0.305974 0.899796 0.417418 0.714061 0.598207 0.613911C0.778995 0.51376 1.00152 0.514489 1.1816 0.61582Z" fill="white" />
    </svg>
}

const PauseIcon: FC = () => {
    return <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" width="3" height="16" rx="1.5" fill="#F8F8FF"/>
        <rect x="10" width="3" height="16" rx="1.5" fill="#F8F8FF"/>
    </svg>
}

const WaveformTrack = styled.div`
    flex-grow: 1;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const WaveformBar = styled.div<{ feedbackTheme: FeedbackTheme, height: number, active: boolean }>`
    width: 3px;
    height: ${props => props.height}px;
    background-color: ${props => props.active ? '#595C62' : '#C1C1C4'};
    border-radius: 1.5px;
`;

const TimeText = styled.div<{ feedbackTheme: FeedbackTheme, active: boolean }>`
    color: ${props => props.active ? props.feedbackTheme.colors.background : props.feedbackTheme.colors.foreground};
    font-size: 10px;
    font-family: Work Sans;
    font-weight: 500;
    line-height: 15.07px;
    letter-spacing: 0.81px;
    word-wrap: break-word;
`

interface Props {
    feedbackTheme: FeedbackTheme;
    waveform: number[]; // 15 values between 0 and 1
    active: boolean;
    completion: number; // 0 to 1
    time: number;
    onClick?: () => void;
}

const PlaybackVisualizer: FC<Props> = ({ feedbackTheme, waveform, active, time, completion, onClick }) => {
    return <Container feedbackTheme={feedbackTheme} active={active} onClick={onClick} >
        <PlayPauseButton feedbackTheme={feedbackTheme}>{ active ? <PauseIcon /> : <PlayIcon /> }</PlayPauseButton>
        <WaveformTrack>
            {waveform.map((height, i) => <WaveformBar key={i} feedbackTheme={feedbackTheme} height={height * 40 + 8} active={active && completion > i / waveform.length} />)}
        </WaveformTrack>
        <TimeText feedbackTheme={feedbackTheme} active={active}>{formatDuration(time)}</TimeText>
    </Container>
}

export default PlaybackVisualizer;
