import React, {FC, useState} from "react";
import {Column, Row} from "../atoms/Layout";
import Background from "./components/Background";
import {Body, Label, MainTitle} from "../atoms/Typography";
import LightInput from "./components/LightInput";
import LightSelect from "./components/SelectInput";
import {SelectOption} from "../atoms/SelectInput";
import paperclip from './components/paperclip.png';
import {HorizontalSpaceFlex1} from "../atoms/Space";
import colors from "../atoms/colors";
import nextArrow from "./components/nextArrow.png";

interface Props {
    companyName: string;
    onSubmit: (topicListId: string, question: string, logo?: File) => void;
}





const topicLists: SelectOption[] = [
    { value: 'CUSTOMER_SERVICE', label: 'Improve my customer service' },
    { value: 'ONLINE_COURSE_FEEDBACK', label: 'Gather feedback for an online course' },
    {value: 'WEBINAR_FEEDBACK',  label: 'Gather feedback for webinars' },
    {value: 'MARKETING_CAMPAIGN_FEEDBACK',label: 'Understand how a marketing campaign was received' },
    {value: 'SALES_PRESENTATIONS_IMPROVEMENT',  label: 'Improve my sales presentations'  },
    {value: 'CONSULTING_SERVICES_IMPROVEMENT', label: 'Improve my consulting services' },
    {value: 'UX_SERVICE_TEST',label: 'Test the user experience (UX) of a service' },
    {value: 'EMPLOYEE_SATISFACTION_IMPROVEMENT', label: 'Improve employee satisfaction'},
    {value: 'CUSTOMER_STORE_EXPERIENCE',  label: 'Improve the experience of customers to my store'},
    {value: 'CONFERENCE_FEEDBACK', label: 'Gather feedback on a conference' },
    {value: 'GRADUATE_PROGRAM_IMPROVEMENT',label: 'Improve a graduate programme'  },
    {value: 'APPRENTICESHIP_PROGRAM_IMPROVEMENT',  label: 'Improve an apprenticeship programme' },
    {value: 'INCUBATOR_ACCELERATOR_IMPROVEMENT', label: 'Improve an incubator or accelerator programme' },
    {value: 'SOFTWARE_TOOL_FEEDBACK', label: 'Gather feedback about a software tool or service'},
    {value: 'IT_SERVICES_IMPROVEMENT', label: 'Improve our IT services' },
]

const suggestQuestion = (topicListId: string, companyName: string): string => {
    switch (topicListId) {
        case 'CUSTOMER_SERVICE':
            return `How can ${companyName} improve its customer service?`
        case 'ONLINE_COURSE_FEEDBACK':
            return `What feedback do you have for ${companyName}'s online course?`
        case 'WEBINAR_FEEDBACK':
            return `What feedback do you have for ${companyName}'s webinars?`
        case 'MARKETING_CAMPAIGN_FEEDBACK':
            return `How was ${companyName}'s marketing campaign received?`
        case 'SALES_PRESENTATIONS_IMPROVEMENT':
            return `How can ${companyName} improve its sales presentations?`
        case 'CONSULTING_SERVICES_IMPROVEMENT':
            return `How can ${companyName} improve its consulting services?`
        case 'UX_SERVICE_TEST':
            return `How can ${companyName} improve the user experience of its services?`
        case 'EMPLOYEE_SATISFACTION_IMPROVEMENT':
            return `How can ${companyName} improve employee satisfaction?`
        case 'CUSTOMER_STORE_EXPERIENCE':
            return `How can ${companyName} improve the experience of customers to its store?`
        case 'CONFERENCE_FEEDBACK':
            return `What feedback do you have for ${companyName}'s conference?`
        case 'GRADUATE_PROGRAM_IMPROVEMENT':
            return `How can ${companyName} improve its graduate programme?`
        case 'APPRENTICESHIP_PROGRAM_IMPROVEMENT':
            return `What are your goals or aspirations for your apprenticeship at ${companyName}?`
        case 'INCUBATOR_ACCELERATOR_IMPROVEMENT':
            return `How can ${companyName} improve its incubator or accelerator programme?`
        case 'SOFTWARE_TOOL_FEEDBACK':
            return `What feedback do you have for ${companyName}'s software tool or service?`
        case 'IT_SERVICES_IMPROVEMENT':
            return `How can ${companyName} improve its IT services?`
        default:
            return ''
    }
}

const Stage4Question: FC<Props> = ({onSubmit, companyName}) => {
    const [topicListId, setTopicListId] = useState<string>('APPRENTICESHIP_PROGRAM_IMPROVEMENT')
    const [question, setQuestion] = useState<string>(suggestQuestion('APPRENTICESHIP_PROGRAM_IMPROVEMENT', companyName))
    const [logo, setLogo] = useState<File | null>(null)

    const updateTopicListId = (value: string) => {
        setTopicListId(value);
        setQuestion(suggestQuestion(value,  companyName));
    }

    const submit = () => {
        onSubmit(topicListId, question, logo || undefined)
    }

    return (
        <Background dark={false}>
            <Row hcenter>
                <Column gap="small" style={{maxWidth: 400, flexGrow: 1}}>
                    <MainTitle style={{ lineHeight: "30px", fontWeight: 500, fontSize: "20px", maxWidth: "70%"}}>Let's get your first question ready...</MainTitle>
                    <Label style={{ color: "black"}}>What do you want to achieve?</Label>
                    <LightSelect value={topicListId} onChange={updateTopicListId} options={topicLists} />
                    <Label style={{ color: "black"}}>Here's a suggested question - feel free to adjust it as needed.</Label>
                    <LightInput value={question} onChange={setQuestion} />
                    <Label style={{ color: "black"}}>Let's personalise your feedback page - Upload a PNG logo (max 2MB)</Label>
                    <Row vcenter nowrap>
                        <img src={paperclip} alt="Upload logo" width="64" height="64" onClick={() => {
                            const input = document.querySelector('input[type="file"]')
                            if (input) {
                                (input as any).click()
                            }
                        }}/>
                        <input type="file" style={{display: 'none'}} accept="image/png" onChange={e => {
                            const file = e.target.files?.[0]
                            if (file) {
                                setLogo(file)
                            }
                        }}/>
                        <Body style={{ color: "black", whiteSpace: "nowrap", textOverflow: 'ellipsis', maxWidth: 200, overflow: 'hidden'}}>
                            { logo ? logo.name : 'No file selected'}
                        </Body>

                    </Row>

                    <Row vcenter>
                        <Label style={{ color: "black"}}>Step 2 of 3</Label>
                        <HorizontalSpaceFlex1 />
                        <button style={{padding: "0 32px", border: "none", borderRadius: 8, background: colors.brand, color: "white"}} onClick={submit}>
                            <img src={nextArrow} alt="Next" width="48" height="48" />
                        </button>
                    </Row>

                </Column>
            </Row>
        </Background>
    );
}

export default Stage4Question;