
import React, { FC } from 'react';
import { Column, Row } from 'atoms/Layout';
import Logo from 'atoms/Logo';
import { Body } from 'atoms/Typography';

const PrivacyPolicy: FC = () => {
    return <Column size="full" padding="large" gap="medium">
        <Row padding="large">
            <Logo />
        </Row>
        <Body>
            <section className="prose">
                <h1>Privacy Policy</h1>

                <h2>BACKGROUND:</h2>
                <p>Mood Group Limited understands that your privacy is important to you and that you care about how your
                    personal data is used. We respect and value the privacy of all of our customers and will only collect
                    and use personal data in ways that are described here, and in a way that is consistent with our
                    obligations and your rights under the law.</p>

                <h2>1. Information About Us</h2>
                <p>Mood Group Limited registered in England with company number 12982969</p>
                <p>Main trading address: International House, 24 Holborn Viaduct, London, United Kingdom, EC1A 2BN</p>
                <p>Data Protection Officer: Martin Tomov.</p>
                <p>Email address: support@itsyello.ai</p>
                <p>Postal address: International House, 24 Holborn Viaduct, London, United Kingdom, EC1A 2BN</p>

                <h2>2. What Does This Notice Cover?</h2>
                <p>This Privacy Information explains how we use your personal data: how it is collected, how it is held, and
                    how it is processed. It also explains your rights under the law relating to your personal data.</p>

                <h2>3. What Is Personal Data?</h2>
                <p>Personal data is defined by the UK GDPR and the Data Protection Act 2018 (collectively, “the Data
                    Protection Legislation”) as ‘any information relating to an identifiable person who can be directly or
                    indirectly identified in particular by reference to an identifier’.</p>
                <p>Personal data is, in simpler terms, any information about you that enables you to be identified. Personal
                    data covers obvious information such as your name and contact details, but it also covers less obvious
                    information such as identification numbers, electronic channel data, and other online identifiers.</p>
                <p>The personal data that we use is set out in Part 5, below.</p>

                <h2>4. What Are My Rights?</h2>
                <p>Under the Data Protection Legislation, you have the following rights, which we will always work to
                    uphold:</p>
                <p>a) The right to be informed about our collection and use of your personal data. This Privacy Notice
                    should tell you everything you need to know, but you can always contact us to find out more or to ask
                    any questions using the details in Part 11.</p>
                <p>b) The right to access the personal data we hold about you. Part 10 will tell you how to do this.</p>
                <p>c) The right to have your personal data rectified if any of your personal data held by us is inaccurate
                    or incomplete. Please contact us using the details in Part 11 to find out more.</p>
                <p>d) The right to be forgotten, i.e. the right to ask us to delete or otherwise dispose of any of your
                    personal data that we hold. Please contact us using the details in Part 11 to find out more.</p>
                <p>e) The right to restrict (i.e. prevent) the processing of your personal data.</p>
                <p>f) The right to object to us using your personal data for a particular purpose or purposes.</p>
                <p>g) The right to withdraw consent. This means that, if we are relying on your consent as the legal basis
                    for using your personal data, you are free to withdraw that consent at any time.</p>
                <p>h) The right to data portability. This means that, if you have provided personal data to us directly, we
                    are using it with your consent or for the performance of a contract, and that data is processed using
                    automated means, you can ask us for a copy of that personal data to re-use with another service or
                    business in many cases.</p>
                <p>i) Rights relating to automated decision-making and profiling. we do not use your personal data in this
                    way.</p>
                <p>For more information about our use of your personal data or exercising your rights as outlined above,
                    please contact us using the details provided in Part 11.</p>
                <p>It is important that your personal data is kept accurate and up-to-date. If any of the personal data we
                    hold about you changes, please keep us informed as long as we have that data.</p>
                <p>Further information about your rights can also be obtained from the Information Commissioner’s Office or
                    your local Citizens Advice Bureau.</p>
                <p>If you have any cause for complaint about our use of your personal data, you have the right to lodge a
                    complaint with the Information Commissioner’s Office. We would welcome the opportunity to resolve your
                    concerns ourselves, however, so please contact us first, using the details in Part 11.</p>

                <h2>5. What Personal Data Do You Collect and How?</h2>
                <p>We may collect and hold some or all of the personal and non-personal data set out in the table below,
                    using the methods also set out in the table. We do not collect any ‘special category’ or ‘sensitive’
                    personal data.</p>

                <table>
                    <tbody>
                    <tr>
                        <td>Data Collected</td>
                        <td>How We Collect the Data</td>
                    </tr>
                    <tr>
                        <td>Identity Information including Full name.</td>
                        <td>Yello server. Gmail.</td>
                    </tr>
                    <tr>
                        <td>Contact information including business name, job title, business address, email and phone
                            number.
                        </td>
                        <td>Yello server. Gmail.</td>
                    </tr>
                    <tr>
                        <td>Business information including business name, job title, business address, email and phone
                            number.
                        </td>
                        <td>Yello server. Gmail.</td>
                    </tr>
                    <tr>
                        <td>Profile information including purchase history</td>
                        <td>Yello Dashboard Usage</td>
                    </tr>
                    <tr>
                        <td>Data from third parties including information, profile information. Full name, business name,
                            job title, business address, email, phone number
                        </td>
                        <td>Yello server. Gmail.</td>
                    </tr>
                    </tbody>
                </table>

                <h2>6. How Do You Use My Personal Data?</h2>
                <p>Under the Data Protection Legislation, we must always have a lawful basis for using personal data. The
                    following table describes how we may use your personal data, and our lawful bases for doing so:</p>

                <table>
                    <tbody>
                    <tr>
                        <td>What We Do</td>
                        <td>What We Do</td>
                        <td>Our Lawful Basis</td>
                    </tr>
                    <tr>
                        <td>Administering our business.</td>
                        <td>Full name.</td>
                        <td>To record voice, supply feedback on customers experience. To provide analytical information for
                            customers dashboard.
                        </td>
                    </tr>
                    <tr>
                        <td>Supplying our products and services to you.</td>
                        <td>Name. Business address, email &amp; contact number.</td>
                        <td>To send out welcome pack to customers address</td>
                    </tr>
                    <tr>
                        <td>Managing payments for our products and services.</td>
                        <td>bank account number, sort code, billing address and name.</td>
                        <td>Stripe</td>
                    </tr>
                    <tr>
                        <td>Personalising and tailoring our products and services for you.</td>
                        <td>Full name.</td>
                        <td>To record voice, supply feedback on customers experience. To provide analytical information for
                            customers dashboard.
                        </td>
                    </tr>
                    <tr>
                        <td>Communicating with you.</td>
                        <td>business name, job title, business address, email and phone number.</td>
                        <td>Gmail. To inform you in regards to onboarding, abandoned sign ups, upcoming payments due/to be
                            taken.
                        </td>
                    </tr>
                    <tr>
                        <td>Supplying you with information by email or post that you have opted-in-to (you may opt-out at
                            any time by emailing support@itsyello.ai)
                        </td>
                        <td>email and phone number.</td>
                        <td>Upcoming new offers &amp; discounts. Announcements.</td>
                    </tr>
                    </tbody>
                </table>

                <p>With your permission and/or where permitted by law, we may also use your personal data for marketing
                    purposes, which may include contacting you by email and telephone and text message or post with
                    information, news, and offers on our products and services. You will not be sent any unlawful marketing
                    or spam. We will always work to fully protect your rights and comply with our obligations under the Data
                    Protection Legislation and the Privacy and Electronic Communications (EC Directive) Regulations 2003,
                    and you will always have the opportunity to opt-out. </p>
                <p>We will only use your personal data for the purpose(s) for which it was originally collected unless we
                    reasonably believe that another purpose is compatible with that or those original purpose(s) and need to
                    use your personal data for that purpose. If we do use your personal data in this way and you wish us to
                    explain how the new purpose is compatible with the original, please contact us using the details in Part
                    11.</p>
                <p>If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the
                    purpose(s) for which it was originally collected,[we will inform you and explain the legal basis which
                    allows us to do so.</p>
                <p>In some circumstances, where permitted or required by law, we may process your personal data without your
                    knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and
                    your legal rights.</p>

                <h2>7. How Long Will You Keep My Personal Data?</h2>
                <p>We will not keep your personal data for any longer than is necessary in light of the reason(s) for which
                    it was first collected. Your personal data will therefore be kept for the following periods (or, where
                    there is no fixed period, the following factors will be used to determine how long it is kept):</p>

                <table>
                    <tbody>
                    <tr>
                        <td>Type of Data</td>
                        <td>How Long We Keep It</td>
                    </tr>
                    <tr>
                        <td>Identity Information including Full name</td>
                        <td>As long as a customer has an active subscription with Mood Group Limited or 3 years after a
                            customer has cancelled all subscriptions.
                        </td>
                    </tr>
                    <tr>
                        <td>Contact information including business name, job title, business address, email and phone
                            number.
                        </td>
                        <td>As long as a customer has an active subscription with Mood Group Limited or 3 years after a
                            customer has cancelled all subscriptions.
                        </td>
                    </tr>
                    <tr>
                        <td>Business information including business name, job title, business address, email and phone
                            number.
                        </td>
                        <td>As long as a customer has an active subscription with Mood Group Limited or 3 years after a
                            customer has cancelled all subscriptions.
                        </td>
                    </tr>
                    <tr>
                        <td>Payment information including details, bank account number, sort code, billing address and
                            name.].
                        </td>
                        <td>As long as a customer has an active subscription with Mood Group Limited or 3 years after a
                            customer has cancelled all subscriptions.
                        </td>
                    </tr>
                    <tr>
                        <td>Profile information including, purchase history.</td>
                        <td>As long as a customer has an active subscription with Mood Group Limited or 3 years after a
                            customer has cancelled all subscriptions.
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h2>8. How and Where Do You Store or Transfer My Personal Data?</h2>

                <p>We will store some of your personal data in the UK. This means that it will be fully protected under the
                    Data Protection Legislation.</p>
                <p>We will only store your personal data store some of your personal data within the European Economic Area
                    (the “EEA”). The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein. This
                    means that your personal data will be fully protected under the EU GDPR and/or to equivalent standards
                    by law. Transfers of personal data to the EEA from the UK are permitted without additional
                    safeguards.</p>
                <p>We may store some or all of your personal data in countries outside of the UK. These are known as “third
                    countries”. We will take additional steps in order to ensure that your personal data is treated just as
                    safely and securely as it would be within the UK and under the Data Protection Legislation as
                    follows:</p>
                <p>We ensure that your personal data is protected under binding corporate rules. Binding corporate rules are
                    a set of common rules which all our companies are required to follow when processing personal data. For
                    further information, please refer to <a href="https://ico.org.uk/" target="_blank">the Information
                        Commissioner’s Office</a>.</p>

                <h2>9. How Can I Access My Personal Data?</h2>

                <p>If you want to know what personal data we have about you, you can ask us for details of that personal
                    data and for a copy of it (where any such personal data is held). This is known as a “subject access
                    request”.</p>
                <p>All subject access requests should be made in writing and sent to the email or postal addresses shown in
                    Part 11.</p>
                <p>There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded
                    or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our
                    administrative costs in responding.</p>
                <p>We will respond to your subject access request within 21 days and, in any case, not more than one month
                    of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data
                    within that time. In some cases, however, particularly if your request is more complex, more time may be
                    required up to a maximum of three months from the date we receive your request. You will be kept fully
                    informed of our progress.</p>

                <h2>10. How Do I Contact You?</h2>
                <p>To contact us about anything to do with your personal data and data protection, including to make a
                    subject access request, please use the following details [(for the attention of Adil Sheikh:</p>
                <p>Email address: support@itsyello.ai</p>
                <p>Postal address: International House, 24 Holborn Viaduct, London, United Kingdom, EC1A 2BN</p>

                <h2>Changes to this Privacy Notice</h2>
                <p>We may change this Privacy Notice from time to time. This may be necessary, for example, if the law
                    changes, or if we change our business in a way that affects personal data protection.</p>
                <p>Any changes will be made available on our website. This Privacy Notice was last updated on 21st September
                    2022.</p>

            </section>
        </Body>

    </Column>
}

export default PrivacyPolicy;
