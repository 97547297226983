import React, { FC } from 'react';
import { Column, Row } from 'atoms/Layout';
import Logo from 'atoms/Logo';
import { Body } from 'atoms/Typography';

const TermsOfService: FC = () => {
    return <Column size="full" padding="large" gap="medium">
        <Row padding="large">
            <Logo />
        </Row>
        <Body>
            <section className="prose">
                <h1>Customer Terms and Conditions</h1>

                <p>This page sets out the terms of business on which we supply any of the Products and/or Services
                    referred to on our website at https://www.itsyello.ai/ (“Our Website”) to you. You should read
                    these terms of business carefully and in full before using, or ordering, any Products and/or
                    Services on Our Website (references to Our Website shall include any desktop or mobile or other
                    device application which we might make available). By using or ordering any of our Products or
                    Services, you agree to be bound by these terms and conditions.</p>
                <p>Please use the method indicated on the relevant page of Our Website to indicate that you accept them.
                    If you do not accept these terms and conditions, you are not permitted to use or order any Products
                    or Services on Our Website.</p>
                <p>Our Website is a website operated by Yello Limited of International House, 24 Holborn Viaduct,
                    London, United Kingdom, EC1A 2BN and referred to throughout these terms and conditions as “the
                    Supplier”, “we” or “us” or similar. </p>

                <h2>1. Application of Terms and Conditions</h2>

                <p>1.1 The Supplier shall supply and the Customer shall purchase the Goods and Services in accordance
                    with the accepted order which shall be subject to these Terms and Conditions; and</p>
                <p>1.2 The Contract shall be to the exclusion of any other terms and conditions subject to which any
                    such quotation is accepted or purported to be accepted, or any such order is made or purported to be
                    made, by the Customer.</p>

                <h2>2. Terms used throughout</h2>

                <p>In these Terms and Conditions, unless the context otherwise requires, the following expressions have
                    the following meanings</p>
                <p>‘Content’ means, amongst other things: any text, images, sounds, videos or other materials of
                    whatever nature on Our Website or the Dashboard.</p>
                <p>‘Dashboard’ means the software developed by us to assist the customer in reviewing data obtained from
                    the Yello tiles.</p>
                <p>‘Yello tiles’ means the tiles with QR codes on them to be placed in customer shops to get feedback
                    via voice or text.</p>
                <p>‘Products’ means any products related to our services that we provide as set out on Our Website.</p>
                <p>‘Services’ means any or all of the services available from Our Website.</p>
                <p>Unless the context otherwise requires, each reference in these Terms and Conditions to:</p>
                <p>“writing”, and any cognate expression, includes a reference to any communication effected by
                    electronic or facsimile transmission or similar means;</p>
                <p>a statute or a provision of a statute is a reference to that statute or provision as amended or
                    re-enacted at the relevant time;</p>
                <p>“these Terms and Conditions” is a reference to these Terms and Conditions and any Schedules as
                    amended or supplemented at the relevant time;</p>
                <p>a Schedule is a schedule to these Terms and Conditions; and</p>
                <p>a Clause or paragraph is a reference to a Clause of these Terms and Conditions (other than the
                    Schedules) or a paragraph of the relevant Schedule.</p>
                <p>a “Party” or the “Parties” refer to the parties to these Terms and Conditions.</p>
                <p>The headings used in these Terms and Conditions are for convenience only and shall have no effect
                    upon the interpretation of these Terms and Conditions.</p>
                <p>Words imparting the singular number shall include the plural and vice versa.</p>
                <p>References to any gender shall include the other gender.</p>

                <h2>3. Basis of Sale and Services</h2>

                <p>3.1 The Supplier’s employees or agents are not authorised to make any representations concerning the
                    Goods or Services unless confirmed by the Supplier in writing. In entering into the Contract the
                    Customer acknowledges that it does not rely on, and waives any claim for breach of, any such
                    representations which are not so confirmed.</p>
                <p>3.2 No variation to these Terms and Conditions shall be binding unless agreed in writing between
                    authorised representatives of the Customer and the Supplier.</p>
                <p>3.3 Sales literature, price lists and other documents issued by the Supplier in relation to the Goods
                    and Services are subject to alteration without notice and do not constitute offers to sell the Goods
                    which are capable of acceptance. No contract for the sale of the Goods and Services shall be binding
                    on the Supplier unless the Supplier has issued a quotation which is expressed to be an offer to sell
                    the Goods and Services or has accepted an order placed by the Customer by whichever is the earlier
                    of:</p>

                <p className="extra">3.3.1 the Supplier’s written acceptance;</p>
                <p>delivery of the Goods; </p>
                <p className="extra">3.3.2 provision of the Services; or</p>
                <p className="extra">3.3.3 the Supplier’s invoice.</p>
                <p>The contract between us comes into existence only when we email you to confirm that we agree to
                    provide to you the Products and/or Services you have ordered. Your payment does not create a
                    contract. If we decline to provide you with the Products and/or Service ordered we shall immediately
                    return your money to your card or other payment method used.</p>
                <p>3.4 Any typographical, clerical or other accidental errors or omissions in any sales literature,
                    quotation, price list, acceptance of offer, invoice or other document or information issued by the
                    Supplier shall be subject to correction without any liability on the part of the Supplier.</p>
                <p>3.5 We may at our sole discretion refuse to provide the Products and/or Services in any country or
                    region that we may see fit.</p>
                <p>3.6 Your continued use of our Products and/or Services shall be deemed acceptance by you of any
                    changes to how we provide them or of our systems and/or our terms and conditions.</p>

                <h2>4. The Goods</h2>

                <p>4.1 No order submitted by the Customer shall be deemed to be accepted by the Supplier unless and
                    until confirmed in writing by the Supplier's authorised representative.</p>
                <p>4.2 The specification for the Goods shall be that set out in the Supplier’s sales documentation
                    unless varied expressly in the Customer’s order (if such variation(s) is/are accepted by the
                    Supplier). The Goods will only be supplied in the minimum units thereof stated in the Supplier’s
                    price list or in multiples of those units. Orders received for quantities other than these will be
                    adjusted accordingly</p>
                <p>4.3 Illustrations, photographs or descriptions whether in catalogues, brochures, price lists or other
                    documents issued by the Supplier are intended as a guide only and shall not be binding on the
                    Supplier.</p>
                <p>4.4 The Supplier reserves the right to make any changes in the specification of the Goods which are
                    required to conform with any applicable safety or other statutory or regulatory requirements or,
                    where the Goods are to be supplied to the Customer’s specification, which do not materially affect
                    their quality or performance.</p>
                <p>4.5 No order which has been accepted by the Supplier may be cancelled by the Customer except with the
                    agreement in writing of the Supplier on the terms that the Customer shall indemnify the Supplier in
                    full against all loss (including loss of profit), costs (including the cost of all labour and
                    materials used), damages, charges and expenses incurred by the Supplier as a result of such
                    cancellation.</p>

                <h2>5. The Services</h2>
                <p>5.1 With effect from the Commencement Date the Supplier shall, in consideration of the price being
                    paid will provide the Services expressly identified in the accepted order and activation of
                    Yellotiles.</p>
                <p>5.2 The Supplier will use reasonable care and skill to perform the Services identified in the
                    accepted order.</p>
                <p>5.3 The Supplier shall use its reasonable endeavours to complete its obligations under the Contract,
                    but time will not be of the essence in the performance of such obligations.</p>

                <h2>6. Account</h2>
                <p>6.1 When you visit Our Website or the Dashboard, you accept responsibility for any action done by any
                    person in your name or under your account or password. You should take all necessary steps to ensure
                    that the password is kept confidential and secure and should inform us immediately if you have any
                    reason to believe that your password has become known to anyone else, or if the password is being,
                    or is likely to be, used in an unauthorised manner.</p>
                <p>6.2 You agree that you have provided accurate, up to date, and complete information about your
                    business. We are not responsible for any error made as a result of such information being
                    inaccurate. You may not transfer your account or the right to receive any of the Services to any
                    other person.</p>

                <h2>7. Prices</h2>
                <p>7.1 The price of the Goods and Services shall be the price listed on the Supplier’s website current
                    at the date of acceptance of the Customer’s order or such other price as may be agreed in writing by
                    the Supplier and the Customer.</p>
                <p>7.2 Where the Supplier has quoted a price for the Goods other than in accordance with the Supplier’s
                    published price list the price quoted shall be valid for [30 days] days only or such lesser time as
                    the Supplier may specify.</p>
                <p>The Supplier reserves the right, by giving written notice to the Customer at any time before delivery
                    or provision, to increase the price of the Goods and/or Services to reflect any increase in the cost
                    to the Supplier which is due to any factor beyond the control of the Supplier (including, without
                    limitation, any foreign exchange fluctuation, currency regulation, alteration of duties, significant
                    increase in the costs of labour, materials or other costs of manufacture), any change in delivery
                    dates, quantities or specifications for the Goods and services which are requested by the Customer,
                    or any delay caused by any instructions of the Customer or failure of the Customer to give the
                    Supplier adequate information or instructions.</p>
                <p>7.3 Except as otherwise stated under the terms of any accepted order. or in any price list of the
                    Supplier, and unless otherwise agreed in writing between the Customer and the Supplier, all prices
                    are inclusive of the Supplier's charges for packaging and transport.</p>
                <p>7.4 The price is exclusive of any applicable value added tax, excise, sales taxes or levies of a
                    similar nature which are imposed or charged by any competent fiscal authority in respect of the
                    Goods and Services, which the Customer shall be additionally liable to pay to the Supplier.</p>
                <p>7.5 If, in error, we have under-priced an item, we will not be liable to supply the Product or
                    Service to you at the stated price.</p>

                <h2>8. Payment</h2>
                <p>8.1 Subject to any special terms agreed in writing between the Customer and the Supplier, the
                    Supplier shall invoice the Customer for the price of the Goods and Services on or at any time after
                    delivery of the Goods and/or the Provision of the Services (as applicable), unless, in the case of
                    Goods, the Goods are to be collected by the Customer or the Customer wrongfully fails to take
                    delivery of the Goods, in which event the Supplier shall be entitled to invoice the Customer for the
                    price at any time after the Supplier has notified the Customer that the Goods are ready for
                    collection or (as the case may be) the Supplier has tendered delivery of the Goods.</p>
                <p>8.2 The Customer shall pay the price of the Goods (less any discount or credit allowed by the
                    Supplier, but without any other deduction, credit or set off) within 10 Business Days of the date of
                    the Supplier’s invoice or otherwise in accordance with such credit terms as may have been agreed in
                    writing between the Customer and the Supplier in respect of the Contract. Payment shall be made on
                    the due date notwithstanding that delivery or provision may not have taken place and/or that the
                    property in the Goods has not passed to the Customer. The time for the payment of the price shall be
                    of the essence of the Contract. Receipts for payment will be issued only upon request.</p>
                <p>8.3 All payments shall be made to the Supplier as indicated on the form of acceptance or invoice
                    issued by the Supplier.</p>
                <p>8.4 The Supplier is not obliged to accept orders from any customer or buyer who has not supplied the
                    Supplier with references satisfactory to the Supplier. If at any time the Supplier is not satisfied
                    as to the creditworthiness of the Customer it may give notice in writing to the Customer that no
                    further credit will be allowed to the Customer in which event no further goods or services will be
                    delivered or provided to the Customer other than against cash payment and notwithstanding sub-Clause
                    8.2 of these conditions, all amounts owing by the Customer to the Supplier shall be immediately
                    payable in cash.</p>
                <p>8.5 At expiry of any annual subscription and automatic, we shall automatically take payment from your
                    direct debit (normally this is the only method of payment permitted for subscriptions which are paid
                    for periods of less than 1 year), credit card or other payment method of the sum specified on Our
                    Website to renew, if not cancelled in accordance with clause 6.</p>

                <h2>9. Specific Products and Services</h2>
                <p>9.1 Annual subscriptions are available to access the Dashboard and to use the Yello tiles
                    (“Yello Subscription”).</p>
                <p>9.2 Each Yello Subscription includes 4 Yello tiles. Additional tiles are available at the
                    current prices and packages on Our Website. QR codes on Yello tiles are our design and include
                    intellectual property rights belonging to us and must not be copied in any way whatsoever.</p>
                <p>9.3 The Yello Subscription comes with a reasonable allowance of audio minutes to retain feedback,
                    which can be limited at our absolute discretion.</p>
                <p>9.4 If multiple channels are used in one Yello Subscription, you must inform us of the channels
                    and which Yello tiles are in which channel, so we can provide you with information in the
                    Dashboard relating to each channel. Yello tiles cannot be provided, shared, transferred or given
                    to third parties to access, view or use.</p>
                <p>9.5 In relation to the provision of the Yello Subscription or the Staff Subscription, there shall
                    be an initial minimum three month subscription and then the service shall continue to be
                    automatically renewed on a annual basis unless we have received from you a written notice of
                    non-extension at least 30 days prior to the end of the initial term, which can only take effect at
                    the end of the initial term. No full or partial refunds of any payments already made shall be
                    given.</p>
                <p>9.6 The software in Our Website, applications and in the Dashboard is owned by us and the
                    intellectual property rights are not assigned or licenced in any way to you (other than as expressly
                    stated herein) and the customer shall not attempt to copy, reproduce, distribute, modify, adapt,
                    reverse engineer or extract the source, object or other code from any such software.</p>
                <p>9.7 All information, data and intellectual property rights of whatever nature relating to the
                    Dashboard or contained within the Dashboard is owned by us and you are provided with a
                    non-transferrable, non-exclusive licence to view and use the same whilst you have a current
                    subscription with us. Access to use or view the Dashboard and the information contained within
                    cannot be given to parties other than the customer and in relation to the customer, only those
                    persons who are employed by the customer and which it is strictly necessary for them to view it as
                    part of their employment with the customer.</p>

                <h2>10. Delivery and Performance</h2>
                <p>10.1 Delivery of the Goods shall be made by the Supplier delivering the Goods to the place in the
                    United Kingdom specified in the accepted order. or, if no place of delivery is so specified, by the
                    Customer collecting the Goods at the Supplier’s premises at any time after the Supplier has notified
                    the Customer that the Goods are ready for collection.</p>
                <p>10.2 The Delivery Date is approximate only and time for delivery shall not be of the essence unless
                    previously agreed by the Supplier in writing. The Goods may be delivered by the Supplier in advance
                    of the Delivery Date upon giving reasonable notice to the Customer.</p>
                <p>10.3 If the Customer fails to take delivery of the Goods or any part of them on the Delivery Date
                    and/or fails to provide any instructions, documents, licences, consents or authorisations required
                    to enable the Goods to be delivered on that date, the Supplier shall be entitled upon giving written
                    notice to the Customer to store or arrange for the storage of the Goods and then notwithstanding the
                    provisions of sub-Clause 10.1 risk in the Goods shall pass to the Customer, delivery shall be deemed
                    to have taken place and the Customer shall pay to the Supplier all costs and expenses including
                    storage and insurance charges arising from such failure.</p>
                <p>10.4 With effect from the Commencement Date the Supplier shall, in consideration of the price being
                    paid in accordance with these Terms and Conditions and the accepted order. provide the Services
                    expressly identified in the accepted order. </p>

                <h2>11. Non-Delivery of Goods and Services</h2>
                <p>11.1 If the Supplier fails to deliver the Goods or provide the Services or any of them on the
                    Delivery Date (or Commencement Date, as appropriate) other than for reasons outside the Supplier’s
                    reasonable control or the Customer’s or its carrier’s fault:</p>
                <p className="extra">11.1.1 if the Supplier delivers the Goods and/or provides the Services at any time
                    thereafter the Supplier shall have no liability in respect of such late delivery; or</p>
                <p className="extra">11.1.2 if the Customer gives written notice to the Supplier within 5 Business Days
                    after the Delivery Date (or Commencement Date, as appropriate) and the Supplier fails to deliver the
                    Goods and/or Services within 5 Business Days after receiving such notice the Customer may cancel the
                    order and the Supplier’s liability shall be limited to the excess (if any) of the cost to the
                    Customer (in the cheapest available market) of similar goods or services to those not delivered or
                    provided over the price of the Goods or Services not delivered or provided.</p>

                <h2>12. Risk and Retention of Title</h2>
                <p>12.1 Risk of damage to or loss of the Goods shall pass to the Customer at:</p>
                <p className="extra">12.1.2 in the case of Goods to be delivered at the Supplier’s premises, the time
                    when the Supplier notifies the Customer that the Goods are available for collection;</p>
                <p className="extra">12.1.3 in the case of Goods to be delivered otherwise than at the Supplier’s
                    premises, the time of delivery or, if the Customer wrongfully fails to take delivery of the Goods,
                    the time when the Supplier has tendered delivery of the Goods; or</p>
                <p className="extra">12.1.4 in the case of Goods being installed by the Supplier, the time that the
                    Supplier notifies the Customer that the installation is complete.</p>

                <p>12.2 Notwithstanding delivery and the passing of risk in the Goods, or any other provision of these
                    Terms and Conditions, legal and beneficial title to the Goods shall not pass to the Customer until
                    the Supplier has received in cash or cleared funds payment in full of the price of the Goods.</p>
                <p>12.3 Until payment has been made to the Supplier in accordance with these Conditions and title in the
                    Goods has passed to the Customer, the Customer shall be in possession of the Goods as bailee for the
                    Supplier and the Customer shall store the Goods separately and in an appropriate environment, shall
                    ensure that they are identifiable as being supplied by the Supplier and shall insure the Goods
                    against all reasonable risks.</p>
                <p>12.4 The Customer shall not be entitled to pledge or in any way charge by way of security for any
                    indebtedness any of the Goods which remain the property of the Supplier, but if the Customer does so
                    all money owing by the Customer to the Supplier shall (without prejudice to any other right or
                    remedy of the Supplier) forthwith become due and payable.</p>
                <p>12.5 The Supplier reserves the right to repossess any Goods in which the Supplier retains title
                    without notice. The Customer irrevocably authorises the Supplier to enter the Customer’s premises
                    during normal business hours for the purpose of repossessing the Goods in which the Supplier retains
                    title and inspecting the Goods to ensure compliance with the storage and identification
                    requirements.</p>
                <p>12.6 The Customer’s right to possession of the Goods in which the Supplier maintains legal and
                    beneficial title shall terminate if:</p>

                <p className="extra">12.6.1 the Customer commits or permits any material breach of his obligations under
                    these Terms and Conditions;</p>
                <p className="extra">12.6.2 the Customer enters into a voluntary arrangement under Parts I or VIII of
                    the Insolvency Act 1986, the Insolvent Partnerships Order 1994 (as amended), or any other scheme or
                    arrangement is made with his creditors;</p>
                <p className="extra">12.6.3 the Customer is or becomes the subject of a bankruptcy order or takes
                    advantage of any other statutory provision for the relief of insolvent debtors;</p>
                <p className="extra">12.6.4 the Customer convenes any meeting of its creditors, enters into voluntary or
                    compulsory liquidation, has a receiver, manager, administrator or administrative receiver appointed
                    in respect of its assets or undertaking or any part thereof, any documents are filed with the court
                    for the appointment of an administrator in respect of the Customer, notice of intention to appoint
                    an administrator is given by the Customer or any of its directors or by a qualifying floating
                    charge-holder (as defined in paragraph 14 of Schedule B1 of the Insolvency Act 1986), a resolution
                    is passed or petition presented to any court for the winding up of the Customer or for the granting
                    of an administration order in respect of the Customer, or any proceedings are commenced relating to
                    the insolvency or possible insolvency of the Customer.</p>

                <h2>13. Assignment</h2>
                <p>13.1 The Supplier may assign the Contract or any part of it to any person, firm or company without
                    the prior consent of the Customer.</p>
                <p>13.2 The Customer shall not be entitled to assign the Contract or any part of it without the prior
                    written consent of the Supplier.</p>

                <h2>14. Defective Goods</h2>
                <p>14.1 If on delivery any of the Goods are defective in any material respect and either the Customer
                    lawfully refuses delivery of the defective Goods or, if they are signed for on delivery as
                    “condition and contents unknown” the Customer gives written notice of such defect to the Supplier
                    within 5 Business Days of such delivery, the Supplier shall at its option:</p>
                <p className="extra">14.1.1 replace the defective Goods within [5] Business Days of receiving the
                    Customer’s notice; or</p>
                <p className="extra">14.1.2 refund to the Customer the price for those Goods (or parts thereof, as
                    appropriate) which are defective;</p>
                <p>but the Supplier shall have no further liability to the Customer in respect thereof and the Customer
                    may not reject the Goods if delivery is not refused or notice given by the Customer as set out
                    above.</p>
                <p>14.2 No Goods may be returned to the Supplier without the prior agreement in writing of the Supplier.
                    Subject thereto any Goods returned which the Supplier is satisfied were supplied subject to defects
                    of quality or condition which would not be apparent on inspection shall either be replaced free of
                    charge or, at the Supplier’s sole discretion the Supplier shall refund or credit to the Customer the
                    price of such defective Goods but the Supplier shall have no further liability to the Customer.</p>
                <p>14.3 The Supplier shall be under no liability in respect of any defect arising from fair wear and
                    tear, or any wilful damage, negligence, subjection to normal conditions, failure to follow the
                    Supplier’s instructions (whether given orally or in writing), misuse or alteration of the Goods
                    without the Supplier’s prior approval, or any other act or omission on the part of the Customer, its
                    employees or agents or any third party.</p>
                <p>14.4 Goods, other than defective Goods returned by the Customer and accepted by the Supplier may be
                    credited to the Customer at the Supplier’s sole discretion and without any obligation on the part of
                    the Supplier.</p>
                <p>14.5 Subject as expressly provided in these Terms and Conditions, and except where the Goods are sold
                    under a consumer sale, all warranties, conditions or other terms implied by statute or common law
                    are excluded to the fullest extent permitted by law.</p>
                <p>14.6 The Customer shall be responsible for ensuring that, except to the extent that instructions as
                    to the use or sale of the Goods are contained in the packaging or labelling of the Goods, any use or
                    sale of the Goods by the Customer is in compliance with all applicable statutory requirements and
                    that handling and sale of the Goods by the Customer is carried out in accordance with directions
                    given by the Supplier or any competent governmental or regulatory authority and the Customer will
                    indemnify the Supplier against any liability loss or damage which the Supplier might suffer as a
                    result of the Customer’s failure to comply with this condition.</p>

                <h2>15. Customer's Default</h2>
                <p>15.1 If the Customer fails to make any payment on the due date then, without prejudice to any other
                    right or remedy available to the Supplier, the Supplier shall be entitled to:</p>
                <p className="extra">15.1.1 cancel the order or suspend any further deliveries or provision of Goods and
                    Services to the Customer;</p>
                <p className="extra">15.1.2 appropriate any payment made by the Customer to such of the Goods and/or
                    Services (or the goods and/or services supplied under any other contract between the Customer and
                    the Supplier) as the Supplier may think fit (notwithstanding any purported appropriation by the
                    Customer); and</p>
                <p className="extra">15.1.3 charge the Customer interest (both before and after any judgement) on the
                    amount unpaid, at the rate of 4% per annum above the Bank of England base rate from time to time,
                    until payment in full is made (a part of a month being treated as a full month for the purpose of
                    calculating interest).</p>

                <p>15.2 This condition applies if:</p>
                <p className="extra">15.2.1 the Customer fails to perform or observe any of its obligations hereunder or
                    is otherwise in breach of the Contract;</p>
                <p className="extra">15.2.2 the Customer becomes subject to an administration order or enters into a
                    voluntary arrangement under Parts I or VIII of the Insolvency Act 1986 or the Insolvent Partnerships
                    Order 1994 (as amended) or (being an individual or firm) becomes bankrupt or (being a company) goes
                    into liquidation;</p>
                <p className="extra">15.2.3 an encumbrancer takes possession, or a receiver is appointed, of any of the
                    property or assets of the Customer;</p>
                <p className="extra">15.2.4 the Customer ceases, or threatens to cease, to carry on business; or</p>
                <p className="extra">15.2.5 the Supplier reasonably apprehends that any of the events mentioned above is
                    about to occur in relation to the Customer and notifies the Customer accordingly.</p>

                <p>15.3 If sub-Clause 15.2 applies then, without prejudice to any other right or remedy available to the
                    Supplier, the Supplier shall be entitled to cancel the Contract or suspend any further deliveries
                    under the Contract without any liability to the Customer, and if the Goods have been delivered but
                    not paid for the price shall become immediately due and payable notwithstanding any previous
                    agreement or arrangement to the contrary.</p>

                <h2>16. Data Protection</h2>
                <p>16.1 You accept to only use and/or process any personal data received from the Yello tiles or any
                    other data on the Dashboard for the intended purposes and not to share those details with any third
                    party.</p>
                <p>16.2 It is important you keep that information secure and confidential and use it only for the
                    purpose for which it was provided and with the consent of the individual.</p>
                <p>16.3 We are the data controller for data protection purposes and you may be considered to be a data
                    processor. You must comply with your obligations with all data protection laws in such
                    circumstances.</p>

                <p>16.4 You agree to comply with UK and EU GDPR legislation when storing, processing, and transferring
                    personal information and:</p>
                <p className="extra">16.4.1 You will protect the data and ensure that it is stored securely.</p>
                <p className="extra">16.4.2 You will only process data for the purpose for which it was provided.</p>
                <p className="extra">16.4.3 You will assist with subject access requests and potential removal or
                    correction of data.</p>
                <p className="extra">16.4.4 You understand that breaches could incur severe financial penalties for
                    yourself, for us and for any other third parties involved.</p>
                <p className="extra">16.4.5 You understand that this duty of confidentiality is indefinite and continues
                    after the expiry or termination of your subscription or any contract with us.</p>

                <h2>17. Payment Security</h2>
                <p>We take care to make Our Website safe for you to use.</p>
                <p>17.1 We use one or more online payment service providers who will encrypt your card or bank account
                    details in a secure environment.</p>
                <p>17.2 If you have asked us to remember your credit card details for your next purchase or
                    subscription, we will securely store your payment details on our systems. These details will be
                    fully encrypted and only used to process your automatic annual payments or other transactions which
                    you have initiated.</p>

                <h2>18. Use of Our Website</h2>
                <p>18.1 If you violate Our Website, we may take legal action against you.</p>
                <p>18.2 You now agree that you will not, and will not allow any other person to:</p>
                <p className="extra">18.2.1 modify, copy, or cause damage or unintended effect to any portion of Our
                    Website, or any software used within it.</p>
                <p className="extra">18.2.2 link to Our Website in any way that would cause the appearance or
                    presentation of Our Website to be different from what would be seen by a user who accessed Our
                    Website by typing the URL into a standard browser;</p>
                <p className="extra">18.2.3 download any part of Our Website, without our express written consent;</p>
                <p className="extra">18.2.4 collect or use any product listings, descriptions, or prices;</p>
                <p className="extra">18.2.5 collect or use any information obtained from or about Our Website or the
                    Content except as agreed or intended by the subscription you have;</p>
                <p className="extra">18.2.6 share with a third party any login credentials to Our Website or the
                    Dashboard.</p>

                <h2>19. Disclaimers and limitation of liability</h2>
                <p>19.1 The Supplier will not by reason of any representation, implied warranty, condition or other
                    term, or any duty at common law or under express terms of the Contract (or these Terms and
                    Conditions), be liable for any loss of profit or any indirect, special or consequential loss,
                    damage, costs, expenses or other claims (whether caused by the Supplier’s servants or agents or
                    otherwise) which arise out of or in connection with the supply of the Goods and Services.</p>
                <p>19.2 All warranties, conditions and other terms implied by statute or common law (save for the
                    conditions implied by section 12 of the Sale of Goods Act 1979) are, to the fullest extent permitted
                    by law, excluded from the Contract.</p>
                <p>19.3 The Customer shall indemnify the Supplier against all damages, costs, claims and expenses
                    suffered by the supplier, arising from loss or damage to any equipment (including that of third
                    parties) caused by the Customer, its agents or employees.</p>
                <p>19.4 Where the Customer consists of two or more persons such expression throughout shall mean and
                    include such two or more persons and each or any of them. All obligations on the part of such a
                    Customer shall be joint and several obligations of such persons.</p>
                <p>19.5 The Supplier shall not be liable to the Customer or be deemed to be in breach of these terms and
                    conditions by reason of any delay in performing, or any failure to perform, any of the Supplier’s
                    obligations if the delay or failure was due to any cause beyond the Supplier’s reasonable
                    control.</p>

                <p>19.6 Nothing in these Terms and Conditions excludes or limits the liability of the Supplier:</p>
                <p className="extra">19.6.1 for death or personal injury caused by the Supplier’s negligence;</p>
                <p className="extra">19.6.2 for any matter which it would be illegal for the Supplier to exclude or
                    attempt to exclude its liability; or</p>
                <p className="extra">19.6.3 for fraud or fraudulent misrepresentation.</p>
                <p>19.7 Subject to the remaining provisions of this Clause 19:</p>
                <p className="extra">19.7.1 the Supplier’s total liability in contract, tort (including negligence or
                    breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with
                    the performance or contemplated performance of the Contract shall be limited to the Contract Price;
                    and</p>
                <p className="extra">19.7.2 the Supplier shall not be liable to the Customer for any pure economic loss,
                    loss of profit, loss of business, depletion of goodwill or otherwise, in each case whether direct,
                    indirect or consequential, or any claims for consequential compensation whatsoever (howsoever
                    caused) which arise out of or in connection with the Contract.</p>

                <p>19.8 All implied conditions, warranties and terms are excluded from all contracts subject to these
                    terms and conditions. If in any jurisdiction an implied condition, warrant or term cannot be
                    excluded, then this sub paragraph shall be deemed to be reduced in effect, only to the extent
                    necessary to release that specific condition, warranty or term.</p>
                <p>19.9 We make no representation or warranty that the Products and/or Services will be:</p>
                <p className="extra">19.9.1 useful to you;</p>
                <p className="extra">19.9.2 of satisfactory quality;</p>
                <p className="extra">19.9.3 fit for a particular purpose;</p>
                <p className="extra">19.9.4 available or accessible, without interruption, or without error.</p>

                <p>19.10 We claim no expert knowledge in any subject. We disclaim any obligation or liability to you
                    arising directly or indirectly from information you take from Our Website.</p>
                <p>19.11 You agree that in any circumstances when we may become liable to you, the limit of our
                    liability is the amount you have paid us in the immediately preceding 12 month period for the
                    Services concerned.</p>

                <p>19.12 We shall not be liable to you for any loss or expense which is:</p>
                <p className="extra">19.12.1 indirect or consequential loss; or</p>
                <p className="extra">19.12.2 economic loss or other loss of turnover, profits, business or goodwill,
                    even if such loss was reasonably foreseeable or we knew you might incur it.</p>
                <p>19.13 This paragraph (and any other paragraph which excludes or restricts our liability) applies to
                    our directors, officers, employees, subcontractors, agents and affiliated companies (who may enforce
                    this provision under the Contracts (Rights of Third Parties) Act 1999), as well as to us.</p>

                <h2>20. Confidentiality</h2>
                <p>20.1 Each Party undertakes that, except as provided by sub-Clause 20.2 or as authorised in writing by
                    the other Party, it shall, at all times during the continuance of the Contract and for 5 years after
                    its termination:</p>
                <p className="extra">20.1.1 keep confidential all Confidential Information;</p>
                <p className="extra">20.1.2 not disclose any Confidential Information to any other person;</p>
                <p className="extra">20.1.3 not use any Confidential Information for any purpose other than as
                    contemplated by and subject to these Terms and Conditions and the Contract;</p>
                <p className="extra">20.1.4 not make any copies of, record in any way or part with possession of any
                    Confidential Information; and</p>
                <p className="extra">20.1.5 ensure that none of its directors, officers, employees, agents or advisers
                    does any act which, if done by that Party, would be a breach of the provisions of sub-clauses 20.1.1
                    to 20.1.4 above.</p>

                <p>20.2 Either Party may:</p>
                <p className="extra">20.2.1 disclose any Confidential Information to:</p>
                <p className="extra">20.2.1.1 any sub-contractor or supplier of that Party;</p>
                <p className="extra">20.2.1.2 any governmental or other authority or regulatory body; or</p>
                <p className="extra">20.2.1.2 any employee or officer of that Party or of any of the aforementioned
                    persons, parties or bodies;</p>
                <p>to such extent only as is necessary for the purposes contemplated by these Terms and Conditions and
                    the Contract, or as required by law, and in each case subject to that Party first informing the
                    person, party or body in question that the Confidential Information is confidential and (except
                    where the disclosure is to any such body as is mentioned in sub-Clause 20.2.1.2 above or any
                    employee or officer of any such body) obtaining and submitting to the other Party a written
                    undertaking from the person in question, as nearly as practicable in the terms of this Clause 20, to
                    keep the Confidential Information confidential and to use it only for the purposes for which the
                    disclosure is made; and</p>

                <p className="extra">20.2.2 use any Confidential Information for any purpose, or disclose it to any
                    other person, to the extent only that it is at the date of the Contract, or at any time after that
                    date becomes, public knowledge through no fault of that Party, provided that in doing so that Party
                    does not disclose any part of that Confidential Information which is not public knowledge.</p>
                <p>20.3 The provisions of this Clause 20 shall continue in force in accordance with their terms,
                    notwithstanding the termination of the Contract for any reason.</p>

                <h2>21. Communications</h2>
                <p>21.1 All notices under these Terms and Conditions and under the Contract shall be in writing and be
                    deemed duly given if signed by, or on behalf of, a duly authorised officer of the Party giving the
                    notice.</p>
                <p>21.2 Notices shall be deemed to have been duly given:</p>
                <p className="extra">21.2.1 when delivered, if delivered by courier or other messenger (including
                    registered mail) during the normal business hours of the recipient; or</p>
                <p className="extra">21.2.2 when sent, if transmitted by facsimile or e-mail and a successful
                    transmission report or return receipt is generated; or</p>
                <p className="extra">21.2.3 on the fifth business day following mailing, if mailed by national ordinary
                    mail, postage prepaid; or</p>
                <p className="extra">21.2.4 on the tenth business day following mailing, if mailed by airmail, postage
                    prepaid.</p>

                <p>21.3 All notices under this Agreement shall be addressed to the most recent address, e-mail address,
                    or facsimile number notified to the other Party.</p>

                <h2>22. Term of agreement and termination</h2>
                <p>22.1 All contracts subject to these terms and conditions shall operate for the period for which you
                    have subscribed to receive the Services (subject to any minimum term).</p>
                <p>22.2 We may terminate a contract subject to these terms and conditions at any time with immediate
                    effect by sending you notice to that effect by email, should you:</p>
                <p className="extra">22.2.1 be unable to meet your obligations or your breach these terms and
                    conditions; or</p>
                <p className="extra">22.2.2 take any step or action in connection with entering administration,
                    provisional liquidation or any composition or arrangement with your creditors (other than in
                    relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court,
                    unless for the purpose of a solvent restructuring), having a receiver appointed to any of your
                    assets or, if the step or action is taken in another jurisdiction, in connection with any analogous
                    procedure in the relevant jurisdiction; or</p>
                <p className="extra">22.2.3 you suspend, or threaten to suspend, or cease or threaten to cease to carry
                    on all or a substantial part of your business;</p>
                <p>and in any of those cases, any monies owing from you to us shall become immediately payable.</p>

                <p>22.3 Termination by either party shall have the following effects:</p>
                <p className="extra">22.3.1 your right to use the Products and/or Services immediately ceases;</p>
                <p className="extra">22.3.2 your right to access the Dashboard and any information within it shall
                    immediately cease;</p>
                <p className="extra">22.3.3 there shall be no re-imbursement in whole or partially, or credit given.</p>

                <h2>23. Interruption to Services</h2>
                <p className="extra">23.1 If it is necessary for us to interrupt the Services, we will give you
                    reasonable notice where this is possible and when we think the down time is such as to justify
                    telling you.</p>
                <p className="extra">23.2 You acknowledge that the Services may also be interrupted for many reasons
                    beyond our control.</p>
                <p className="extra">23.3 You agree that we are not liable to you for any loss, foreseeable or not,
                    arising from any interruption to the Services.</p>

                <h2>24. Indemnity</h2>
                <p>You agree to indemnify us against any loss, damage or liability, suffered by us at any time and
                    arising out of:</p>
                <p className="extra">24.1 any act, neglect or default of yours in connection with this agreement or your
                    use of the Products and/or Services;</p>
                <p className="extra">24.2 your breach of this agreement;</p>
                <p className="extra">24.3 your failure to comply with any law;</p>
                <p className="extra">24.4 a claim arising from your use of the Products and/or Services (including a
                    claim against us by any of your staff or customers).</p>

                <h2>25. Force Majeure</h2>
                <p>Neither Party shall be liable for any failure or delay in performing their obligations where such
                    failure or delay results from any cause that is beyond the reasonable control of that Party. Such
                    causes include, but are not limited to: power failure, Internet Service Provider failure, industrial
                    action, civil unrest, fire, flood, storms, earthquakes, acts of terrorism, acts of war, governmental
                    action or any other event that is beyond the control of the Party in question.</p>

                <h2>26. Waiver</h2>
                <p>The Parties agree that no failure by either Party to enforce the performance of any provision in
                    these Terms and Conditions or under the Contract shall constitute a waiver of the right to
                    subsequently enforce that provision or any other provision. Such failure shall not be deemed to be a
                    waiver of any preceding or subsequent breach and shall not constitute a continuing waiver.</p>

                <h2>27. Severance</h2>
                <p>The Parties agree that, in the event that one or more of the provisions of these Terms and Conditions
                    or the Contract are found to be unlawful, invalid or otherwise unenforceable, that / those
                    provisions shall be deemed severed from the remainder of these Terms and Conditions (and, by
                    extension, the Contract). The remainder of these and the Contract shall be valid and
                    enforceable.</p>

                <h2>28. Third Party Rights</h2>
                <p>A person who is not a party to the Contract shall have no rights under the Contract pursuant to the
                    Contracts (Rights of Third Parties) Act 1999.</p>

                <h2>29. Law and Jurisdiction</h2>
                <p>29.1 These Terms and Conditions and the Contract (including any non-contractual matters and
                    obligations arising therefrom or associated therewith) shall be governed by, and construed in
                    accordance with, the laws of England and Wales.</p>
                <p>29.2 Any dispute, controversy, proceedings or claim between the Parties relating to these Terms and
                    Conditions or to the Contract (including any non-contractual matters and obligations arising
                    therefrom or associated therewith) shall fall within the jurisdiction of the courts of England and
                    Wales.</p>
            </section>
        </Body>
    </Column>
}

export default TermsOfService;
