import React, { FC } from 'react';
import { Column, Row } from 'atoms/Layout';
import Panel from 'atoms/Panel';
import InsightScore from 'organisms/InsightScore';
import { useAppContext } from 'context/AppContext';
import Loading from 'atoms/Loading';
import {Label, Subtitle, Title} from 'atoms/Typography';
import { SENTIMENT_NAMES, toSentiment } from 'model/feedback';
import OverallSentimentBreakdown from 'organisms/OverallSentimentBreakdown';
import Logo from "../atoms/Logo";
import colors from "../atoms/colors";
import {HorizontalSpaceFlex1} from "../atoms/Space";
import InformationTooltip from "../molecules/InformationTooltip";
import {TopicListScore} from "../api/topicLists";
import {Insights} from "../api/insights";

interface Props {
    channelId?: string;
    insights?: Insights;
}

const InsightScores: FC<Props> = ({ insights, channelId }) => {
    const { feedback, channels, topicLists } = useAppContext();
    const channel = channels.value?.find(c => c.id === channelId);

    const relatedFeedback = feedback.value?.filter(f => !channelId || f.channelId === channelId) ?? []

    const filterFeedbackByTopic = (topics: string[]) => {
        // Return only feedback that has a topic that matches one of the topics in the array
        return relatedFeedback.filter(f => f.analysis.topics.some(t => topics.includes(t.id)))
    }
    const averageSentiment = relatedFeedback.length ? relatedFeedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / relatedFeedback.length : 0;
    const sentiment = toSentiment(averageSentiment)
    const topicList = topicLists.value?.find(tl => tl.id === channel?.topicListId)
    const scores: TopicListScore[] | undefined = topicList?.scores

    if (feedback.loading || channels.loading || topicLists.loading) {
        return <Column gap="large" size="half">
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
        </Column>
    }

    return <Column gap="large" size="half">
        <Panel>
            <Column gap="medium">
                <Label>Overall Sentiment</Label>
                <Row>
                    <Title>{SENTIMENT_NAMES[sentiment]}</Title>
                </Row>
                <OverallSentimentBreakdown feedback={relatedFeedback} />
            </Column>
        </Panel>
        <Panel>
            <Column gap="large">
                <Row>
                    <HorizontalSpaceFlex1 />
                    <InformationTooltip>
                        Introducing the Yello Index: leveraging contextual AI to dynamically create tailored score guides and metrics based on your industry and unique mission goals. This innovative framework helps define your success and development trajectory, providing clear, actionable insights to achieve your objectives.
                    </InformationTooltip>
                </Row>
                <Row center>
                    <Subtitle style={{ fontSize: 27 }}>Your&nbsp;</Subtitle><Logo size="small" /> <Subtitle style={{ fontSize: 27 }}>&nbsp;score</Subtitle>
                </Row>
                <div style={{ borderBottom: `3px solid ${colors.brand}`, marginBottom: 20, marginLeft: 20, marginRight: 20 }} />

                {
                    scores?.map(score => {
                        const insightScore = insights?.scores?.find(s => s.id === score.id)

                        return <>
                            <InsightScore key={score.id}
                                          title={score.name}
                                          feedback={filterFeedbackByTopic(score.topics)}
                                          explanation={insightScore?.content}/>
                        </>
                    })
                }
            </Column>
        </Panel>
    </Column>
}

export default InsightScores;
