import {SelectOption} from "../../atoms/SelectInput";
import React from "react";
import styled from "styled-components";
import colors from "../../atoms/colors";

interface Props {
    value: string;
    onChange: (value: string) => void;
    options: SelectOption[];
}

const StyledSelect = styled.select`
    padding: 24px 8px;
    border: 1px solid ${colors.brand};
    border-radius: 4px;
    background: white;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 48px);
`;


const LightSelect = ({ value, onChange, options }: Props) => {
    const selectRef = React.useRef<HTMLSelectElement>(null);

    return <StyledSelect value={value} onChange={e => onChange(e.target.value)} ref={selectRef}>
            {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
        </StyledSelect>

}

export default LightSelect;
