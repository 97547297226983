import styled from 'styled-components';
import { Sentiment } from 'model/feedback';
import colors from "./colors";

const SentimentCard = styled.div<{ sentiment?: Sentiment }>`
    min-width: 400px;
    max-width: 400px;
    height: 500px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    border-radius: 20px;
    background: ${colors.brand};
    color: ${colors.textLight};
    padding: 35px;
    box-shadow: 0 4px 35px 0 #E0E0ED;
    cursor: pointer;
  
    transition: transform 0.2s ease-in-out;
  
    &:hover {
      transform: translate(0px, -5px);
    }
  
    @media (max-width: 768px) {
        min-width: 300px;
        max-width: 768px;
        flex-grow: 1;
    }
`;

export default SentimentCard;
