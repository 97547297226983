import React, { FC, useEffect } from 'react';
import { Body } from './Typography';
import { DateRangePicker, defaultStaticRanges, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import styled from 'styled-components';
import { ChevronDown } from './Icon/index';
import moment, { Moment } from 'moment';
import { StaticRange, Range } from 'react-date-range';
import colors from './colors';

interface Props {
    startDate: Moment;
    endDate: Moment;
    onChange: (startDate: Moment, endDate: Moment) => void
}

const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    gap: 8px;
`;

const Popover = styled.div`
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 600px;
    z-index: 1000;
  
    @media (max-width: 600px) {
      & .rdrCalendarWrapper {
        display: none;
      }
    }
  
    & > * {
      font-family: 'WORK Sans' !important;
    }
`;

const DateRangeInput: FC<Props> = ({ startDate, endDate, onChange }) => {
    const [showPopover, setShowPopover] = React.useState(false);
    const popoverRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (showPopover) {
            // Hide the popover if the user clicks outside of it
            const listener = (event: MouseEvent) => {
                if (showPopover && popoverRef.current && !event.composedPath().includes(popoverRef.current)) {
                    setShowPopover(false);
                }
            }

            document.addEventListener('click', listener);
            return () => {
                document.removeEventListener('click', listener);
            }
        }
    }, [showPopover]);

    const staticRanges: StaticRange[] = [
        {
            label: 'Last 30 Days',
            range: () => ({
                startDate: moment().subtract(30, 'days').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
            }),
            isSelected: (range: Range) => moment(range.startDate).isSame(moment().subtract(30, 'days').startOf('day'))
                && moment(range.endDate).isSame(moment().endOf('day'))
        },
        ...defaultStaticRanges,
        {
            label: 'All Time',
            range: () => ({
                startDate: moment('2020-01-01').toDate(),
                endDate: moment().endOf('day').toDate(),
            }),
            isSelected: (range: Range) => moment(range.startDate).isSame(moment('2020-01-01')) && moment(range.endDate).isSame(moment().endOf('day'))
        }
    ]

    const textValue = staticRanges.find(range => range.isSelected({
        startDate: startDate.toDate(), endDate: endDate.toDate()
    }))?.label ?? 'Custom Range'

    const handleChange = (ranges: RangeKeyDict) => {
        const range = ranges.selection
        if (range) {
            const startDate = moment(range.startDate)
            const endDate = moment(range.endDate)
            onChange(startDate, endDate)
        }
    }

    return <Container ref={popoverRef}>
        <Body onClick={() => setShowPopover(!showPopover)}>
            {textValue}
        </Body>
        <ChevronDown width="24" height="24" onClick={() => setShowPopover(!showPopover)}/>
        { showPopover && <Popover>
            <DateRangePicker
                ranges={[{
                    startDate: startDate.toDate(),
                    endDate: endDate.toDate(),
                    key: 'selection',
                    color: colors.brand
                }]}
                staticRanges={staticRanges}
                maxDate={moment().endOf('day').toDate()}
                onChange={handleChange}
                inputRanges={[]}
                />
        </Popover> }
    </Container>
}

export default DateRangeInput;
